<template>
  <FlotoFullCalendar
    v-if="isChangeTypeLoaded"
    :calendar-options="calendarOptions"
  >
    <template v-slot:eventContent="{ event: info }">
      <MTooltip :overlay-style="{ maxWidth: '800px' }">
        <template v-slot:trigger>
          <div class="text-base text-ellipsis">
            <b>{{ info.timeText }}</b>
            <a
              class="text-white hover:text-white"
              :href="hrefLink(info)"
              target="_blank"
            >
              {{ info.event.title }}
            </a>
          </div>
        </template>
        <div class="change-tooltip">
          <div>
            <a
              class="text-white hover:text-white"
              :href="hrefLink(info)"
              target="_blank"
            >
              <b>{{ info.event.title }}</b>
            </a>
            <MDivider class="my-1" />
            <div class="info-content">
              <div class="mr-2">{{ $tc('schedule') }}: </div>
              <div>
                {{ info.event.extendedProps.startDate | datetime }} -
                {{ info.event.extendedProps.endDate | datetime }}
              </div>
            </div>
            <div
              v-if="info.event.extendedProps.changeTypeId"
              class="info-content"
            >
              <div class="mr-2">{{ $tc('change') }} {{ $tc('type') }}: </div>
              <div>
                {{
                  (
                    changeTypeOptions.find(
                      (t) => t.id === info.event.extendedProps.changeTypeId
                    ) || {}
                  ).text
                }}
              </div>
            </div>
            <div class="info-content">
              <div class="mr-2">{{ $tc('requester') }}: </div>
              <div>
                {{ info.event.extendedProps.requesterData.name }}
              </div>
            </div>
            <div class="info-content">
              <div class="mr-2">{{ $tc('created_time') }}: </div>
              <div>
                {{ info.event.extendedProps.createdAt | datetime }} ({{
                  info.event.extendedProps.createdAt | timeago
                }})
              </div>
            </div>
          </div>
        </div>
      </MTooltip>
    </template>
  </FlotoFullCalendar>
</template>

<script>
import FlotoFullCalendar from '@components/full-calendar/full-calendar'
import { authComputed } from '@state/modules/auth'
import {
  ChangeTypeComputed,
  ChangeTypeMethods,
} from '@state/modules/change-type'
import { initialEventAdapter } from '@components/full-calendar/event-utils'
export default {
  name: 'ChangeCalendarView',
  components: { FlotoFullCalendar },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    moduleName: {
      type: String,
      default() {
        return this.$constants.CHANGE
      },
    },
    initialDate: {
      type: [String, Number],
      default: undefined,
    },
  },
  computed: {
    ...authComputed,
    ...ChangeTypeComputed,
    calendarOptions() {
      return {
        initialEvents: this.initialEvents,
        initialDate: this.initialDate,
      }
    },
    initialEvents() {
      const items = this.items.map((i) => ({
        ...i,
        backgroundColor: this.changeTypeColorsMap[i.changeTypeId] || '',
      }))
      return initialEventAdapter(items)
    },
  },
  created() {
    if (!this.isChangeTypeLoaded) {
      this.fetchChangeTypes()
    }
  },
  methods: {
    ...ChangeTypeMethods,
    hrefLink(info) {
      const link = this.$modules.getModuleRoute('ticket', 'view', {
        params: {
          id: info.event.extendedProps.id,
          ticketType: this.moduleName,
        },
      })
      return this.$router.resolve(link).href
    },
  },
}
</script>
<style lang="less" scoped>
.change-tooltip {
  max-height: 400px;
  overflow-y: auto;
  word-break: break-all;
  .info-content {
    @apply text-sm;

    display: flex;
  }
}
</style>
