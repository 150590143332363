<template>
  <AdditionalInfoExpandable :module-name="moduleName">
    <MCollapse
      :bordered="false"
      default-active-key="all"
      class="flex-1 size-small"
    >
      <MCollapsePanel v-for="stage in availabelStages" :key="stage.key">
        <template v-slot:header>
          <h6 class="mb-0 text-primary text-sm">
            {{ stage.text }}
          </h6>
        </template>
        <MRow>
          <MCol :size="9">
            <AdditionalInfo
              :stage="stage.key"
              :disabled="isDisabled(stage.key)"
              :module-name="moduleName"
              :update-fn="handleUpdateMoreDetails"
              :resource="resource"
              v-bind="$attrs"
            />
          </MCol>
        </MRow>
      </MCollapsePanel>
    </MCollapse>
  </AdditionalInfoExpandable>
</template>

<script>
import { StatusComputed } from '@state/modules/status'
import { FormComputed } from '@state/modules/form'
import IsEqual from 'lodash/isEqual'
import Pick from 'lodash/pick'
import GroupBy from 'lodash/groupBy'
import { changeStages, releaseStages } from '@utils/status'
import AdditionalInfo from '@components/additional-information/additional-info'
import AdditionalInfoExpandable from '@components/additional-information/additional-info-expandable'

export default {
  name: 'ChangeFormConsumer',
  components: { AdditionalInfoExpandable, AdditionalInfo },
  props: {
    disabled: { type: Boolean, default: false },
    isChangeDisabled: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
    updateApi: { type: Function, default: undefined },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...FormComputed,
    ...StatusComputed,
    initialStages() {
      return [{ text: this.$t('all'), key: 'all' }].concat(
        this.stagesList.filter((s) => s.key !== 'approval')
      )
    },
    stagesList() {
      if (this.moduleName === this.$constants.RELEASE) {
        return releaseStages()
      }
      return changeStages()
    },
    availabelStages() {
      if (!this[`${this.moduleName}Fields`]) {
        throw new Error(`given module ${this.moduleName} is not found in store`)
      }
      if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        const fields = this[`${this.moduleName}Fields`]()
        const groupedFields = GroupBy(fields, 'stage')
        const stages = Object.keys(groupedFields).map((k) => k)
        return this.initialStages.filter((s) => stages.indexOf(s.key) >= 0)
      }
      return []
    },
    currentStage() {
      if (this.resource.statusId) {
        return (
          this[`${this.moduleName}FindStatusFromId`](this.resource.statusId) ||
          {}
        ).stage
      }
      return undefined
    },
  },
  methods: {
    handleUpdateMoreDetails(data) {
      const diff = Object.keys(data).filter(
        (k) => !IsEqual(this.resource.fieldValueDetails[k], data[k])
      )
      return this.updateApi({
        ...Pick(data, diff),
        fieldValueDetails: { ...this.resource.fieldValueDetails },
      })
    },
    isDisabled(stage) {
      if (this.disabled) {
        return true
      }
      if (stage === 'all' && !this.isChangeDisabled) {
        return false
      }
      if (stage === 'all' && this.currentStage === 'review') {
        return false
      }
      if (stage !== this.currentStage) {
        return true
      }
    },
  },
}
</script>
