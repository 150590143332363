<template>
  <MRow class="my-5 items-center" :gutter="0">
    <MCol id="technician-picker" :size="3">
      <FlotoTechnicianPicker
        :value="value.technicianId"
        :disabled="disabled"
        :placeholder="disabled ? '---' : undefined"
        :group-id="value.groupId"
        change-technician-on-group-change
        @change="handleChange({ technicianId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            v-bind="slotData"
            :lable="$tc('assigned_to')"
            icon-name="vector"
            :disabled="disabled"
          >
            <template v-slot:icon>
              <FlotoUserAvatar
                :size="34"
                :avatar="(slotData.currentItem || {}).avatar"
                :style="{
                  minWidth: `${24}px`,
                }"
              />
            </template>
          </FlotoDropdownTrigger>
        </template>
      </FlotoTechnicianPicker>
    </MCol>
    <MCol
      v-if="
        moduleName !== $constants.CHANGE && moduleName !== $constants.RELEASE
      "
      id="start-end-date-picker"
      :size="3"
    >
      <FlotoTriggerView
        icon-name="calendar-alt"
        :lable="`${$tc('end')} ${$tc('date')}`"
        :value="
          !(value.timeRange || {}).endTime
            ? '---'
            : value.timeRange.endTime | datetime
        "
      />
    </MCol>
    <MCol id="technician-group-picker" :size="3">
      <FlotoTechnicianGroupPicker
        :value="value.groupId"
        :disabled="disabled"
        @change="handleChange({ groupId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            :disabled="disabled"
            v-bind="slotData"
            :lable="`${$tc('technician_group')}`"
            icon-name="users"
          />
        </template>
      </FlotoTechnicianGroupPicker>
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'SecondRow',
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
    isTicketClosed: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>
