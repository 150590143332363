import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.department` },
    meta: {
      moduleName,
      breadcrumbKey: 'organization',
      nonLinked: true,
    },
    children: [
      {
        path: 'department',
        name: `${routeNamePrefix}.department`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/department-list'
            )
          ),
        meta: {
          breadcrumbKey: 'department',
        },
      },
      {
        path: 'location',
        name: `${routeNamePrefix}.location`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/location-list'
            )
          ),
        meta: {
          breadcrumbKey: 'location',
        },
      },
      {
        path: 'priority',
        name: `${routeNamePrefix}.priority`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/priority-list'
            )
          ),
        meta: {
          breadcrumbKey: 'priority',
        },
      },
      {
        path: 'impact',
        name: `${routeNamePrefix}.impact`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "organization" */ './views/impact-list')
          ),
        meta: {
          breadcrumbKey: 'impact',
        },
      },
      {
        path: 'urgency',
        name: `${routeNamePrefix}.urgency`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/urgency-list'
            )
          ),
        meta: {
          breadcrumbKey: 'urgency',
        },
      },
      {
        path: 'announcements',
        name: `${routeNamePrefix}.announcement`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/announcement-list'
            )
          ),
        meta: {
          breadcrumbKey: 'announcement',
        },
      },
      {
        path: 'branding',
        name: `${routeNamePrefix}.branding`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "organization" */ './views/branding')
          ),
        meta: {
          breadcrumbKey: 'branding',
        },
      },
      {
        path: 'task-type',
        name: `${routeNamePrefix}.task-type`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/task-type-list'
            )
          ),
        meta: {
          breadcrumbKey: 'task_type',
        },
      },
      {
        path: 'account',
        name: `${routeNamePrefix}.account`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "organization" */ './views/account')
          ),
        meta: {
          breadcrumbKey: 'account',
        },
      },
      {
        path: 'system-preference',
        name: `${routeNamePrefix}.system-preference`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/system-preference'
            )
          ),
        meta: {
          breadcrumbKey: 'system_preference',
        },
      },
      {
        path: 'security',
        name: `${routeNamePrefix}.security`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "organization" */ './views/security')
          ),
        meta: {
          breadcrumbKey: 'security',
        },
      },
      {
        path: 'msp',
        name: `${routeNamePrefix}.msp`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "organization" */ './views/msp')
          ),
        meta: {
          breadcrumbKey: 'msp',
        },
      },
      {
        path: 'tenant-management',
        name: `${routeNamePrefix}.tenant-management`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/tenant-management-list'
            )
          ),
        meta: {
          breadcrumbKey: 'tenant_management',
        },
      },
    ],
  },
]
