<template>
  <FlotoFixedView>
    <FlotoPageHeader
      :title="`${resource.name}: ${resource.subject}`"
      class="px-4"
      use-divider
    >
      <template v-slot:back-button>
        <slot name="before-title"></slot>
      </template>
      <template v-slot:title-append>
        <slot name="title-append">
          <MTooltip v-if="!disabled">
            <template v-slot:trigger>
              <MButton
                variant="neutral-light"
                class="mx-1"
                :shadow="false"
                shape="circle"
                @click="handleEditSubject"
              >
                <MIcon name="pencil" />
              </MButton>
            </template>
            {{ $t('edit') }}
          </MTooltip>
        </slot>
      </template>
      <div class="flex justify-between">
        <small class="text-neutral">
          <template v-if="showRequester">
            <template v-if="isApproval">
              {{ $t('requested') }}
            </template>
            {{ $t('by') }}
            <FlotoUserDrawer
              :disabled="isPortalLogin"
              :user="resource.requesterData"
            />
            {{ $t('at') }}
          </template>
          {{ resource.createdAt | datetime }}
          ({{ resource.createdAt | timeago }})
        </small>
        <MTooltip
          v-if="
            !isPortalLogin &&
            moduleName === $constants.REQUEST &&
            resource.rating > 0
          "
        >
          <template v-slot:trigger>
            <span>
              <MRate
                allow-half
                disabled
                class="mr-8"
                :value="resource.rating"
              />
            </span>
          </template>
          {{ tenantPrefrences.feedbackRatingValues.value[resource.rating - 1] }}
        </MTooltip>
      </div>
      <slot name="additional-header-content" />
      <template v-slot:after-title>
        <slot name="actions" />
      </template>
    </FlotoPageHeader>
    <FlotoScrollView>
      <MCol :size="12" class="px-6" :style="defaultStyle">
        <MRow class="flex-no-wrap">
          <MCol
            class="transition-width main-detail-container"
            v-bind="detailSize"
          >
            <slot
              v-if="
                resource.description &&
                (!allowedFields || allowedFields.indexOf('description') >= 0)
              "
              name="description"
            >
              <div>
                <h6 class="text-primary">
                  {{ $t('description') }}
                </h6>
              </div>
              <div class="description mb-2 mr-4">
                <FlotoReadMore :height="20 * 3">
                  <FlotoRichEditor :value="resource.description" disabled />
                </FlotoReadMore>
                <MDivider />
              </div>
            </slot>
            <slot name="primary-row">
              <PrimaryRow
                v-model="primaryInfo"
                :disabled="disabled"
                :module-name="moduleName"
                :hidden-fields="hiddenFields"
                :is-required-fn="isRequiredFn"
              />
            </slot>
            <slot name="secondary-row">
              <SecondaryRow
                v-model="secondaryInfo"
                :disabled="disabled"
                :is-ticket-closed="isTicketClosed"
                :module-name="moduleName"
                :resource="resource"
                :hidden-fields="hiddenFields"
                :is-required-fn="isRequiredFn"
              />
            </slot>
            <slot name="tags-row">
              <TagsRow
                v-model="tagsInfo"
                :disabled="disabled"
                :module-name="moduleName"
                :resource="resource"
                :is-approval="isApproval"
                :approval="approval"
                :is-required-fn="isRequiredFn"
                @identifiedAsProblem="$emit('identifiedAsProblem')"
                @purchaseRequest="$emit('purchaseRequest')"
              />
            </slot>
            <slot v-if="allowAttachments" name="attachments">
              <FlotoAttachment
                :disabled="disabled"
                :value="resource.fileAttachments"
                only-list
                @change="updateResource({ fileAttachments: $event })"
              />
            </slot>
            <slot name="additional-fields">
              <AdditionalInfoWithExpand
                :resource="resource"
                :module-name="moduleName"
                :show-no-data="false"
                :disabled="disabled"
                :col-size="additionalInfoColSize"
                :update-fn="handleUpdateMoreDetails"
                :default-expanded="isPortalLogin"
                :is-approval="isApproval"
                :is-form-rules-available="formRulesContext.hasRequestFormRules"
                @edit-additional-info="handleEditSubject"
              />
            </slot>
            <slot />
          </MCol>
          <MCol
            v-if="hasSidebar"
            v-bind="sidebarSize"
            class="border border-neutral-lighter sidebar-border border-b-0 border-t-0 border-solid transition-width py-6 relative pages-inner-right-slide-panel"
            style="min-height: 120px"
          >
            <slot v-if="sidebarVisible" name="sidebar">
              <div class="overflow-x-hidden">
                <Sidebar
                  v-model="sidebarInfo"
                  :disabled="disabled"
                  :module-name="moduleName"
                  :change-stage="changeStage"
                  :is-ticket-closed="isTicketClosed"
                  :is-ticket-resolve="isTicketResolve"
                  :resource="resource"
                  :hidden-fields="hiddenFields"
                  :is-required-fn="isRequiredFn"
                />
              </div>
            </slot>
            <div class="toggle">
              <a
                href="javascript:;"
                class="text-neutral hover:text-neutral"
                @click="sidebarVisible = !sidebarVisible"
              >
                <MIcon :name="`chevron-${isRtl ? sidebarRtl : sidebarMain}`" />
              </a>
            </div>
          </MCol>
        </MRow>
      </MCol>
    </FlotoScrollView>
    <FlotoDrawerForm
      v-if="!disabled"
      :open="isEditingSubject"
      @cancel="isEditingSubject = false"
      @submit="handleUpdateSubject"
    >
      <template v-slot:header> {{ $t('edit') }}: {{ resource.name }} </template>
      <FlotoFormItem
        id="subject-input"
        v-model="internalValueModel.subject"
        :label="titleLabel"
        :placeholder="titleLabel"
        rules="required|subject"
      />
      <FlotoFormItem
        v-if="!allowedFields || allowedFields.indexOf('description') >= 0"
        id="description-input"
        :label="descriptionLabel"
      >
        <FlotoRichEditor
          v-model="internalValueModel.description"
          :placeholder="descriptionLabel"
          :affix-toolbar="false"
        />
      </FlotoFormItem>
      <FlotoFormItem
        v-if="allowAttachments"
        id="attachment-input"
        :label="$tc('attachment', 2)"
      >
        <FlotoAttachment
          v-model="internalValueModel.fileAttachments"
          @upload-start="updatingSubject = true"
          @upload-done="updatingSubject = false"
        />
      </FlotoFormItem>
      <template v-slot:actions="{ submit, hide }">
        <MButton id="update-btn" :loading="updatingSubject" @click="submit">
          {{ $t('update') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" class="ml-2" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </FlotoFixedView>
</template>

<script>
import IsEqual from 'lodash/isEqual'
import Pick from 'lodash/pick'
import { authComputed } from '@state/modules/auth'
import AdditionalInfoWithExpand from '@components/additional-information/additional-info-expandable'
import PrimaryRow from './primary-row'
import SecondaryRow from './secondary-row'
import TagsRow from './tags-row'
import Sidebar from './sidebar'
import { PreferenceComputed } from '@state/modules/preference'
import { FormComputed } from '@state/modules/form'

export default {
  name: 'ResourceDetail',
  components: {
    PrimaryRow,
    SecondaryRow,
    TagsRow,
    Sidebar,
    AdditionalInfoWithExpand,
  },
  inject: ['formRulesContext'],
  props: {
    disabled: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    isApproval: { type: Boolean, default: false },
    approval: { type: Object, default: undefined },
    // eslint-disable-next-line
    allowAttachments: { type: Boolean, default: true },
    // eslint-disable-next-line
    hasSidebar: { type: Boolean, default: true },
    // eslint-disable-next-line
    showRequester: { type: Boolean, default: true },
    moduleName: { type: String, default: 'request' },
    updateApi: { type: Function, required: true },
    sidebarWidth: { type: Object, default: () => ({ size: 3 }) },
    detailWidth: { type: Object, default: () => ({ size: 9 }) },
    defaultSidebarVisible: { type: Boolean, default: false },
    allowedFields: { type: Array, default: undefined },
    titleLabel: {
      type: String,
      default: function () {
        return this.$t('subject')
      },
    },
    descriptionLabel: {
      type: String,
      default: function () {
        return this.$t('description')
      },
    },
    isTicketClosed: { type: Boolean, default: false },
    isTicketResolve: { type: Boolean, default: false },
    additionalInfoColSize: { type: Number, default: undefined },
    changeStage: { type: String, default: undefined },
  },
  data() {
    this.defaultStyle = {
      height: `${window.outerHeight}px`,
    }
    // @TODO make this as per preference
    return {
      sidebarVisible: this.defaultSidebarVisible,
      isEditingSubject: false,
      updatingSubject: false,
      internalValueModel: {},
    }
  },
  computed: {
    ...authComputed,
    ...PreferenceComputed,
    ...FormComputed,
    hiddenFields() {
      return this[`${this.moduleName}HiddenFields`] || []
    },
    sidebarRtl() {
      return this.sidebarVisible ? 'left' : 'right'
    },
    sidebarMain() {
      return this.sidebarVisible ? 'right' : 'left'
    },
    detailSize() {
      if (this.hasSidebar && this.sidebarVisible) {
        return this.detailWidth
      }
      return { size: 12 }
    },
    sidebarSize() {
      if (this.sidebarVisible) {
        return this.sidebarWidth
      }
      return { size: undefined }
    },
    primaryInfo: {
      get() {
        return Pick(this.resource, [
          'priorityId',
          'statusId',
          'urgencyId',
          'impactId',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
    secondaryInfo: {
      get() {
        return Pick(this.resource, [
          'requester',
          'technicianId',
          'groupId',
          'categoryId',
          'dueBy',
          'riskTypeId',
          'olaDueBy',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
    tagsInfo: {
      get() {
        return Pick(this.resource, [
          'tags',
          'impactId',
          'spam',
          'reopenCount',
          'slaViolated',
          'responseDueViolated',
          'firstResponseTime',
          'responseDue',
          'dueBy',
          'approvalStatus',
          'vipRequest',
          'knownError',
          'identifiedAsProblem',
          'purchaseRequest',
          'olaDueBy',
          'olaViolated',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
    sidebarInfo: {
      get() {
        return Pick(this.resource, [
          'createdAt',
          'updatedAt',
          'categoryId',
          'suggestedCategoryId',
          'supportLevel',
          'sourceId',
          'locationId',
          'escalationLevel',
          'resolvedAt',
          'closedAt',
          'ccEmailSet',
          'estimatedTime',
          'departmentId',
          'templateId',
          'fileAttachments',
          'requestType',
          'callFrom',
          'resolutionTime',
          'businessServiceId',
          'natureOfProblem',
          'knownError',
          'reasonType',
          'targetEnvironment',
          'changeImplementor',
          'changeManager',
          'changeReviewer',
          'incomingMailbox',
          'releaseEngineer',
          'releaseManager',
          'qaManager',
          'releaseReviewer',
          'affectedServices',
          'violatedSlaId',
          'responseDue',
          'firstResponseTime',
          'changeTypeId',
          'releaseTypeId',
          'companyId',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
  },
  methods: {
    handleUpdateMoreDetails(data) {
      const diff = Object.keys(data).filter(
        (k) => !IsEqual(this.resource.fieldValueDetails[k], data[k])
      )
      return this.updateResource({
        ...Pick(data, diff),
        fieldValueDetails: { ...this.resource.fieldValueDetails },
      })
    },
    updateResource(change) {
      return this.updateApi({ id: this.resource.id, ...change })
    },
    handleEditSubject() {
      if (
        this.moduleName === this.$constants.REQUEST &&
        (this.formRulesContext.hasRequestFormRules ||
          this.formRulesContext.hasServiceCatalogFormRules)
      ) {
        this.formRulesContext.handleShowEditFormModal(this.resource)
      } else {
        this.internalValueModel = Pick(this.resource, [
          'subject',
          'description',
          'fileAttachments',
        ])
        this.isEditingSubject = true
      }
    },
    handleUpdateSubject() {
      this.updatingSubject = true
      this.updateApi(this.internalValueModel)
        .then(() => {
          this.internalValueModel = {}
          this.isEditingSubject = false
        })
        .finally(() => (this.updatingSubject = false))
    },
    setInternalValue(change) {
      this.internalValueModel = {
        ...this.internalValueModel,
        ...(change || {}),
      }
    },
    isRequiredFn(paramName) {
      const fields = this[`${this.moduleName}Form`].fields
      const currentField = (fields || []).find((f) => f.paramName === paramName)
      if (this.isPortalLogin) {
        return (currentField || {}).requesterRequired
      } else {
        return (currentField || {}).required
      }
    },
  },
}
</script>

<style lang="less" scoped>
.sub-heading {
  @apply flex items-center justify-between;
}

.heading {
  @apply items-start flex-wrap flex flex-col;

  .action {
    line-height: 30px;

    @apply ml-2 flex items-center justify-center absolute right-0 top-0;
  }

  .title {
    @apply overflow-hidden relative;
  }
}

.main-detail-container {
  &.@{ant-prefix}-col-12 {
    width: calc(100% - 34px);
  }
}
</style>
