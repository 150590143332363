<template>
  <a @click="triggerApproval">
    <slot>
      <MIcon name="columns" />
      <span class="ml-1">
        {{ $t('split_request') }}
      </span>
    </slot>
    <FlotoDrawerForm
      :open="isDrawerOpen"
      width="65%"
      @cancel="cancelAction"
      @submit="handleFormSubmitted"
    >
      <template v-slot:header>
        {{ `${$t('split_request')}: ${resource.name}` }}
      </template>
      <TicketForm
        v-if="creatingResource"
        ref="formRef"
        :value="creatingResource"
        :module-name="moduleName"
        :with-submit="false"
        :avoid-default-value="true"
        @reset-form="handleResetForm"
        @templateSelected="handleTemplateSelected"
        @change="handleChangeCreatingResource"
      />
      <template v-slot:actions="{ hide, submit }">
        <MButton
          id="create-btn"
          outline
          class="mr-2"
          :loading="creatingRequest"
          @click="submit"
        >
          {{ $t('create') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import { createSplitRequestApi } from '@modules/ticket/ticket-api'
import TicketForm from '@modules/ticket/components/ticket-form'

export default {
  name: 'SplitRequestAction',
  components: { TicketForm },
  props: {
    moduleName: { type: String, required: true },
    resource: { type: Object, required: true },
  },
  data() {
    return {
      isDrawerOpen: false,
      processing: false,
      creatingResource: undefined,
      creatingRequest: false,
      defaultData: undefined,
    }
  },
  watch: {
    resource: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setDefautlData(newValue)
        }
      },
    },
  },
  methods: {
    setDefautlData(resource) {
      // refactor requester_email to requester
      this.creatingResource = {
        ...resource,
        requester: (resource.requesterData || {}).name,
        templateId: 0,
        id: 0,
      }
      this.defaultData = {
        ...resource,
        requester: (resource.requesterData || {}).name,
        templateId: 0,
        id: 0,
      }
    },
    handleResetForm() {
      if (this.resource) {
        this.setDefautlData(this.resource)
      }
    },
    handleTemplateSelected(template) {
      if (!Object.keys(template).length) {
        this.creatingResource = {}
      }
    },
    handleChangeCreatingResource(data) {
      this.creatingResource = {
        ...this.creatingResource,
        ...data,
      }
    },
    cancelAction() {
      this.isDrawerOpen = false
      this.creatingResource = {
        ...this.defaultData,
      }
    },
    triggerApproval() {
      this.$emit('trigger')
      setTimeout(() => {
        this.isDrawerOpen = true
      }, 300)
    },
    handleFormSubmitted() {
      this.creatingRequest = true
      createSplitRequestApi(
        this.moduleName,
        this.creatingResource,
        this.resource.id
      )
        .then((data) => {
          this.$router.push(
            this.$modules.getModuleRoute('ticket', 'view', {
              params: { id: data.id, ticketType: this.moduleName },
            })
          )
        })
        .finally(() => (this.creatingRequest = false))
    },
  },
}
</script>
