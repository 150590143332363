<template>
  <FlotoFixedView>
    <FlotoPageHeader
      :title="`${resource.name}: ${resource.subject}`"
      class="px-4"
      use-divider
    >
      <template v-slot:back-button>
        <slot name="before-title"></slot>
      </template>
      <div class="flex justify-between">
        <small class="text-neutral">
          <template v-if="showRequester">
            <template v-if="isApproval">
              {{ $t('requested') }}
            </template>
            {{ $t('by') }}
            <FlotoUserDrawer
              :disabled="isPortalLogin"
              :user-id="resource.owner"
            />
            {{ $t('at') }}
          </template>
          {{ resource.createdAt | datetime }}
          ({{ resource.createdAt | timeago }})
        </small>
      </div>
      <slot name="additional-header-content" />
      <template v-slot:after-title>
        <slot name="actions" />
      </template>
    </FlotoPageHeader>
    <FlotoScrollView>
      <MCol :size="12" class="px-4" :style="defaultStyle">
        <MRow class="flex-no-wrap">
          <MCol
            class="transition-width main-detail-container"
            v-bind="detailSize"
          >
            <slot
              v-if="
                resource.description &&
                (!allowedFields || allowedFields.indexOf('description') >= 0)
              "
              name="description"
            >
              <div>
                <small class="text-neutral-light">
                  {{ $t('description') }}
                </small>
              </div>
              <div class="description my-2 mr-4">
                <FlotoReadMore :height="20 * 3">
                  <FlotoRichEditor :value="resource.description" disabled />
                </FlotoReadMore>
              </div>
            </slot>
            <slot name="primary-row">
              <PrimaryRow
                v-model="primaryInfo"
                :disabled="disabled"
                :module-name="moduleName"
              />
            </slot>
            <slot name="secondary-row">
              <SecondaryRow
                v-model="secondaryInfo"
                :disabled="disabled"
                :module-name="moduleName"
                :resource="resource"
              />
            </slot>
            <slot name="tags-row">
              <TagsRow
                v-model="tagsInfo"
                :disabled="disabled"
                :module-name="moduleName"
                :resource="resource"
              />
            </slot>
            <slot v-if="allowAttachments" name="attachments">
              <FlotoAttachment
                :disabled="disabled"
                :value="resource.fileAttachemnts"
                only-list
                @change="updateResource({ fileAttachemnts: $event })"
              />
            </slot>
            <slot name="additional-fields">
              <!-- <AdditionalInfoWithExpand
                :resource="resource"
                :module-name="moduleName"
                :show-no-data="false"
                :disabled="disabled"
                :col-size="additionalInfoColSize"
                :update-fn="handleUpdateMoreDetails"
                :default-expanded="isPortalLogin"
                :is-approval="isApproval"
              /> -->
            </slot>
            <slot />
          </MCol>
        </MRow>
      </MCol>
    </FlotoScrollView>
  </FlotoFixedView>
</template>
<script>
import { authComputed } from '@state/modules/auth'
import Pick from 'lodash/pick'
import PrimaryRow from './primary-row'
import SecondaryRow from './secondary-row'
import TagsRow from './tags-row'
export default {
  name: 'Detail',
  components: {
    PrimaryRow,
    SecondaryRow,
    TagsRow,
  },
  props: {
    // eslint-disable-next-line
    showRequester: { type: Boolean, default: true },
    isApproval: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    allowedFields: { type: Array, default: undefined },
    moduleName: { type: String, default: undefined },
    updateApi: { type: Function, required: true },
    // eslint-disable-next-line
    allowAttachments: { type: Boolean, default: true },
  },
  data() {
    this.defaultStyle = {
      height: `${window.outerHeight}px`,
    }
    return {}
  },
  computed: {
    ...authComputed,
    detailSize() {
      return { size: 12 }
    },
    primaryInfo: {
      get() {
        return Pick(this.resource, [
          'statusId',
          'priorityId',
          'taskTypeId',
          'timeRange',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
    secondaryInfo: {
      get() {
        return Pick(this.resource, ['technicianId', 'timeRange', 'groupId'])
      },
      set(value) {
        this.updateResource(value)
      },
    },
    tagsInfo: {
      get() {
        return Pick(this.resource, [
          'completion',
          'duration',
          'estimatedTime',
          'moduleName',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
  },
  methods: {
    updateResource(change) {
      return this.updateApi({ id: this.resource.id, ...change })
    },
  },
}
</script>
