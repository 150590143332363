<template>
  <MRow :gutter="0">
    <MCol>
      <FlotoFormItem
        id="name"
        v-model="folder.name"
        :label="$t('name')"
        auto-focus
        rules="required|short_text"
        :placeholder="$t('name')"
      />
      <FlotoFormItem
        id="description"
        v-model="folder.description"
        :label="$t('description')"
        :rows="2"
        :placeholder="$t('description_instruction')"
        type="textarea"
        rules="required"
      />
      <FlotoFormItem
        id="category-prefix-input"
        rules="min:2|max:5|string_without_space"
        :label="`${$tc('category')} ${$tc('prefix')}`"
        :placeholder="`${$tc('category')} ${$tc('prefix')}`"
      >
        <MInput
          v-model="folder.categoryPrefix"
          style="text-transform: uppercase"
          :placeholder="`${$tc('category')} ${$tc('prefix')}`"
        />
      </FlotoFormItem>
    </MCol>
  </MRow>
</template>
<script>
export default {
  name: 'ServiceCategoryForm',
  props: {
    folder: { type: Object, required: true },
    resetForm: { type: Function, default: undefined },
  },
  methods: {
    handlePrefixChange(value) {
      this.resetForm({
        ...this.folder,
        categoryPrefix: value.toUpperCase(),
      })
    },
  },
}
</script>
