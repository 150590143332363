<template>
  <div>
    <slot>
      <MButton
        id="link-asset-btn"
        type="button"
        variant="neutral"
        class="mr-2"
        @click.prevent.stop="addNewRelation"
      >
        <MIcon name="link" />
        {{ $t('link') }} {{ $tc('asset', 2) }}
      </MButton>
    </slot>
    <MRow v-if="defaultSelectedAssets.length > 0" class="mt-4">
      <MCol>
        <div
          v-for="asset in defaultSelectedAssets"
          :key="`${asset.id}${asset.model}`"
          class="selected-asset mb-2"
        >
          <MTooltip>
            <template v-slot:trigger>
              <div class="flex">
                <span class="text-base text-ellipsis flex-1">
                  {{ asset.name }} {{ asset.displayName }}
                </span>
                <a class="mx-2" @click="removeAsset(asset)">
                  <MTooltip placement="right">
                    <template v-slot:trigger>
                      <MIcon name="trash-alt" class="text-secondary-red" />
                    </template>
                    {{ $t('delete') }}
                  </MTooltip>
                </a>
              </div>
            </template>
            {{ asset.name }} {{ asset.displayName }}
          </MTooltip>
        </div>
      </MCol>
    </MRow>
    <FlotoDrawer :open="showDrawer" width="65%" @hide="hideDrawer">
      <template v-slot:title> {{ $t('link') }} {{ $tc('asset', 2) }} </template>
      <component
        :is="selectionComponent"
        selectable
        :source-module-name="$constants.CHANGE"
        :module-name="$constants.ASSET"
        :searchable="!isPortalLogin"
        :client-side-pagination="isPortalLogin"
        :fetch-fn="isPortalLogin ? fetchEligableRelations : undefined"
        :default-selected-item-ids="defaultSelectedAssets.map((a) => a.id)"
        :linkable="!isPortalLogin"
        :exclude-items-by-id="excludeItemsById"
        :hidden-columns="isPortalLogin ? ['assignedUserIds'] : []"
        @selection-change="setSelectedItems"
        @asset-sub-module-change="handleAssetSubModuleChange"
      />
      <template v-slot:actions="{ hide }">
        <MButton
          :disabled="selectedItems.length === 0"
          outline
          class="mx-1"
          :loading="processing"
          @click="handleAddRelations"
        >
          {{ $t('link') }}
        </MButton>
        <MButton variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </div>
</template>

<script>
import GroupBy from 'lodash/groupBy'
import { authComputed } from '@state/modules/auth'
import { transformAssetForList } from '@data/asset'
import { getMyAssetApi } from '@modules/asset/asset-api'
import AssetSelectionContainer from '@components/relation/asset-selection-container'
import AssetSelectionList from '@components/item-selection-list/asset-list'
export default {
  name: 'LinkAssetDrower',
  components: { AssetSelectionContainer },
  model: {
    event: 'change',
  },
  props: {
    defaultSelectedAssets: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      showDrawer: false,
      processing: false,
      selectedItems: [],
      selectedAssetSubModuleName: null,
      excludeItemsById: [],
    }
  },
  computed: {
    ...authComputed,
    selectionComponent() {
      if (this.isPortalLogin) {
        return AssetSelectionList
      }
      return AssetSelectionContainer
    },
    excludedItemGropByModuleName() {
      if (this.isPortalLogin) {
        return {}
      }
      return GroupBy(this.defaultSelectedAssets, 'model')
    },
  },
  methods: {
    fetchEligableRelations(targetModuleName, criterias, limit, offset) {
      if (this.isPortalLogin) {
        return getMyAssetApi().then((data) => {
          let allItems = (data.objectList || []).map(
            (i) => transformAssetForList({ ...i, relationId: 'fake_id' }) // add fake relation id for combined ids
          )
          if (this.defaultSelectedAssets.length) {
            const selectedItemsIds = this.defaultSelectedAssets.map((d) => d.id)
            allItems = allItems.filter(
              (a) => selectedItemsIds.indexOf(a.id) === -1
            )
          }
          return allItems
          // return {
          //   items: (data.objectList || []).map(
          //     (i) => transformAssetForList({ ...i, relationId: 'fake_id' }) // add fake relation id for combined ids
          //   ),
          //   total: data.totalCount,
          // }
        })
      }
    },
    handleAddRelations() {
      if (this.processing) {
        return
      }
      this.processing = true
      this.$emit('change', this.selectedItems)
      this.processing = false
      this.hideDrawer()
    },
    removeAsset(asset) {
      this.$emit(
        'change',
        this.defaultSelectedAssets.filter((a) => a.guid !== asset.guid)
      )
    },
    handleAssetSubModuleChange(subModuleName) {
      this.selectedAssetSubModuleName = subModuleName
      this.excludeItemsById = (
        this.excludedItemGropByModuleName[subModuleName] || []
      ).map((i) => i.id)
    },
    hideDrawer() {
      this.selectedItems = []
      this.showDrawer = false
    },
    setSelectedItems(items) {
      this.selectedItems = [...this.defaultSelectedAssets, ...items]
      // if (this.isPortalLogin) {
      //   this.selectedItems = items
      // } else {
      //   this.selectedItems = [...this.defaultSelectedAssets, ...items]
      // }
    },
    addNewRelation() {
      setTimeout(() => {
        this.showDrawer = true
        // this.selectedItems = this.defaultselected-item.map((i) => ({ id: i }))
      }, 250)
    },
  },
}
</script>

<style lang="less" scoped>
.selected-asset {
  @apply rounded-lg border inline-flex border-solid border-neutral-lighter px-2 py-1 mr-2 items-center;
  & .text-base {
    max-width: 300px;
  }
}
</style>
