<template>
  <FlotoContentLoader :loading="requestLoading" :height="600">
    <FormConsumer
      ref="formRef"
      :form-fields="allCustomFormFields"
      :module-name="moduleName"
      :value="value"
      :with-submit="withSubmit"
      validate-archived-value
      allow-field-mapping
      :avoid-default-value="avoidDefaultValue"
      :apply-form-rules="moduleName === $constants.REQUEST"
      :form-rules="formRules"
      @templateSelected="$emit('templateSelected', $event)"
      @subject-blur="$emit('subject-blur', $event)"
      @description-blur="$emit('description-blur', $event)"
      @startDate-update="$emit('startDate-update', $event)"
      @endDate-update="$emit('endDate-update', $event)"
      @change="$emit('change', $event)"
      @reset-form="$emit('reset-form', $event)"
      @submit="handleFormSubmitted"
    >
      <template v-slot:submit>
        <span />
      </template>
      <template v-slot:reset>
        <span />
      </template>
      <template v-if="hasAssetModule && useLinkAsset" v-slot:additional-fields>
        <MCol :size="12" class="mb-4 ml-2" :class="{ 'mt-2': !withSubmit }">
          <LinkAssetDrawer
            :module-name="$constants.ASSET"
            :default-selected-assets="selectedAssets"
            @change="handleLinkAssetSelectionChange"
          />
        </MCol>
      </template>
    </FormConsumer>
  </FlotoContentLoader>
</template>

<script>
import IsEqual from 'lodash/isEqual'
import FindIndex from 'lodash/findIndex'
import { LicenseComputed } from '@state/modules/license'
import { FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'
import { PreferenceComputed } from '@state/modules/preference'
import LinkAssetDrawer from '@modules/ticket/components/link-asset-drawer'
import { getFormRulesApi } from '@modules/form-rules/form-rules-api'

export default {
  name: 'TicketForm',
  components: { FormConsumer, LinkAssetDrawer },
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    useTemplate: { type: Boolean, default: true },
    // eslint-disable-next-line
    useRequester: { type: Boolean, default: true },
    // eslint-disable-next-line
    useCcEmail: { type: Boolean, default: true },
    // eslint-disable-next-line
    useLinkAsset: { type: Boolean, default: true },
    // eslint-disable-next-line
    considerPriorityMatrix: { type: Boolean, default: true },
    moduleName: { type: String, required: true },
    withSubmit: { type: Boolean, required: true },
    avoidDefaultValue: { type: Boolean, default: false },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    exclude: {
      type: Array,
      default() {
        return []
      },
    },
    defaultSelectedAssets: {
      type: Array,
      default() {
        return []
      },
    },
    additionalCustomFormFields: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selectedAssets: [],
      formRules: [],
      formRulesLoading: true,
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...FormComputed,
    globalLoading() {
      return this.requestLoading || this.formRulesLoading
    },
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
    customFormFields() {
      if (this.moduleName === this.$constants.REQUEST) {
        const enablePriorityMatrix =
          this.tenantPrefrences.EnablePriorityMatrix &&
          this.tenantPrefrences.EnablePriorityMatrix.value
        let allFields = this.requestFields({
          exclude:
            enablePriorityMatrix && this.considerPriorityMatrix
              ? [
                  'priority',
                  ...(this.useTemplate ? [] : ['template']),
                  ...(this.useCcEmail ? [] : ['ccemail']),
                  ...(this.useRequester ? [] : ['requester']),
                ]
              : [
                  ...(this.useTemplate ? [] : ['template']),
                  ...(this.useCcEmail ? [] : ['ccemail']),
                  ...(this.useRequester ? [] : ['requester']),
                ],
        })
        if (!this.useCcEmail) {
          const i = FindIndex(allFields || [], { paramName: 'requester' })
          if (i !== -1) {
            allFields = [
              ...allFields.slice(0, i),
              {
                ...allFields[i],
                attributes: {
                  ...(allFields[i].attributes || {}),
                  disabled: true,
                },
              },
              ...allFields.slice(i + 1),
            ]
          }
        }
        return allFields
      } else if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        return this[`${this.moduleName}Fields`]({
          exclude: [...(this.useTemplate ? [] : ['template'])],
          stages: ['all'],
        })
      } else {
        return this[`${this.moduleName}Fields`]({
          exclude: [...(this.useTemplate ? [] : ['template'])],
        })
      }
    },
    allCustomFormFields() {
      return [...this.customFormFields, ...this.additionalCustomFormFields]
    },
  },
  watch: {
    defaultSelectedAssets: {
      immediate: true,
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.selectedAssets = [...(newValue || [])]
        }
      },
    },
  },
  created() {
    this.getFormRules()
  },
  methods: {
    getFormRules() {
      if (this.moduleName !== this.$constants.REQUEST) {
        this.formRulesLoading = false
        return
      }
      return getFormRulesApi({
        moduleName: this.moduleName,
        name: '',
      }).then((data) => {
        this.formRules = data.items.filter((i) => i.enabled)
        this.formRulesLoading = false
      })
    },
    handleFormSubmitted(data) {
      this.$emit('submit', {
        ...data,
        linkAssetIds: this.selectedAssets.map((a) => `${a.id}:${a.model}`),
      })
    },
    submit() {
      this.$refs.formRef.submit()
    },
    handleLinkAssetSelectionChange(items) {
      this.selectedAssets = items
      if (!this.withSubmit) {
        this.$emit('change', {
          ...this.value,
          linkAssetIds: items.map((a) => `${a.id}:${a.model}`),
        })
      }
    },
  },
}
</script>
