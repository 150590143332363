<template>
  <div :id="item.name" class="flex flex-1 items-center">
    <InlineEdit
      :field="$tc(type)"
      :rules="rules"
      :value="item.name"
      :item-style="{ display: 'flex', flex: 1, alignItems: 'center' }"
      @change="$emit('update', { ...item, name: $event })"
      @current-editing="$emit('current-editing', $event)"
    >
      <template v-slot:item-renderer="{ edit }">
        <ColorPicker
          v-if="useColor === true"
          id="color-picker"
          v-model="item.color"
          @change="$emit('update', { ...item, color: $event })"
        />
        <div class="item-title flex-1 mx-1">{{ item.name }}</div>
        <HoverableAction v-if="!disabled">
          <template v-slot="{ stickAction, unstickAction }">
            <MTooltip v-if="item.canEdit">
              <template v-slot:trigger>
                <MIcon
                  id="edit-icon"
                  name="pencil"
                  size="lg"
                  class="mx-2 cursor-pointer text-neutral-light"
                  @click="edit"
                />
              </template>
              {{ $t('edit') }}
            </MTooltip>
            <FlotoDeleteBtn
              v-if="item.canDelete && !item.default"
              id="delete-icon"
              class="ml-2"
              :message="
                $t('confirm_remove_item', {
                  item: `${$tc(type)}`,
                })
              "
              @trigger="stickAction"
              @cancel="unstickAction"
              @confirm="$emit('remove', item)"
            >
              <MIcon
                name="times-circle"
                class="text-secondary-red"
                size="lg"
              ></MIcon>
            </FlotoDeleteBtn>
          </template>
        </HoverableAction>
      </template>
    </InlineEdit>
    <MTooltip v-if="!hideDefaultAction">
      <a slot="trigger" href="javascript:;" class="text-neutral-light">
        <MSwitch
          id="default-switch"
          :disabled="item.default"
          size="small"
          class="ml-2"
          :checked="item.default"
          @change="$emit('update', { ...item, default: $event })"
        />
      </a>
      {{ $t('set_as') }} {{ $t('default') }}
    </MTooltip>
  </div>
</template>

<script>
import HoverableAction from '@components/hoverable-action'
import InlineEdit from '@components/inline-edit/inline-edit'
import ColorPicker from '@components/color-picker'

export default {
  name: 'Item',
  components: { InlineEdit, HoverableAction, ColorPicker },
  model: {
    event: 'change',
  },
  props: {
    item: { type: Object, required: true },
    hideDefaultAction: { type: Boolean, required: false },
    type: { type: String, default: 'item' },
    disabled: { type: Boolean, default: false },
    rules: { type: Object, default: undefined },
    useColor: { type: Boolean, default: false },
  },
  methods: {},
}
</script>
