<template>
  <a @click="triggerAction">
    <MIcon name="user-plus" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $t('add') }} {{ $t('watcher') }}
    </span>
    <FlotoDrawerForm
      :open="showForm"
      @submit="handleFormSubmit"
      @cancel="showForm = false"
    >
      <template v-slot:header>
        {{ $t('watcher') }}
      </template>
      <WatcherForm v-model="formData" />
      <template v-slot:actions="{ hide, submit }">
        <MButton
          id="save-btn"
          :loading="processing"
          class="mr-2"
          @click="submit"
          >{{ $t('save') }}</MButton
        >
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import WatcherForm from '@components/watcher/watcher-form'

export default {
  name: 'AddWatcher',
  components: { WatcherForm },
  props: {
    defaultValue: {
      type: Object,
      default() {
        return {}
      },
    },
    updateWatcher: { type: Function, required: true },
  },
  data() {
    return {
      showForm: false,
      processing: false,
      formData: { ...this.defaultValue },
    }
  },
  watch: {
    defaultValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData = { ...newValue }
      }
    },
  },
  methods: {
    triggerAction() {
      this.$emit('trigger')
      setTimeout(() => {
        this.showForm = true
        this.formData = { ...this.defaultValue }
      }, 400)
    },
    handleFormSubmit() {
      this.processing = true
      this.updateWatcher(this.formData)
        .then(() => {
          this.showForm = false
        })
        .finally(() => (this.processing = false))
    },
  },
}
</script>
