<template>
  <div class="flex flex-col h-100">
    <FlotoCrudContainer
      ref="categoryListRef"
      :fetch-fn="getCategories"
      :paging="false"
      :update-fn="updateFn"
      :delete-fn="removeFn"
      :create-fn="createFn"
      :as-table="false"
    >
      <template v-slot:add-controls="{ create, refreshList }">
        <div class="flex flex-col">
          <div class="flex">
            <h5 class="text-primary flex-1">
              {{ $tc('service') }} {{ $tc('category', 2) }}
            </h5>
            <div v-if="!disabled">
              <MTooltip>
                <template v-slot:trigger>
                  <MButton
                    id="add-category-btn"
                    shape="circle"
                    variant="transparent"
                    :shadow="false"
                    @click="create"
                  >
                    <MIcon name="plus-circle" class="text-primary" />
                  </MButton>
                </template>
                {{ $tc('add') }} {{ $tc('service') }} {{ $tc('category', 2) }}
              </MTooltip>
            </div>
          </div>
          <div class="mb-2">
            <MInput
              id="search-box"
              v-model="query"
              type="search"
              :placeholder="$t('search')"
              @search="refreshList"
            />
          </div>
        </div>
      </template>
      <template v-if="!disabled" v-slot:form-header="{ item }">
        {{ $t(item.id ? 'edit' : 'add') }} {{ $tc('service') }}
        {{ $tc('category') }}
      </template>
      <template v-slot:form-items="{ item: folder, resetForm }">
        <ServiceCategoryForm :folder="folder" :reset-form="resetForm" />
      </template>
      <template v-slot:form-actions="{ submit, cancel, item, processing }">
        <MButton
          id="add-update-btn"
          class="mx-1"
          :loading="processing"
          @click="submit"
        >
          {{ $t(item.id ? 'update' : 'add') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="cancel">
          {{ $t('cancel') }}
        </MButton>
      </template>
      <template
        v-slot:list-items="{
          items,
          edit,
          remove,
          replaceAllItems,
          replaceItem,
        }"
      >
        <div class="flex flex-col hierarchy-explorer flex-1 min-w-0">
          <ServiceCategoryItem
            :active="(value || {}).id === 0"
            :folder="{
              id: 0,
              name: `${$tc('all')} ${$tc('service')} ${$tc('category', 2)}`,
            }"
            :disabled="disabled"
            @click="setActiveFolder({ id: 0 })"
          />
          <SortableList
            :value="items"
            :disabled="disabled"
            tag="div"
            filter=".sort-disabled"
            @update="handleBulkUpdate($event, replaceAllItems)"
          >
            <ServiceCategoryItem
              v-for="item in items"
              :key="item.id"
              :disabled="disabled"
              :active="(value || {}).id === item.id"
              :folder="item"
              @click="setActiveFolder(item)"
              @edit="edit(item)"
              @remove="handleRemoveFolder(item, remove, replaceItem)"
            />
            <span />
          </SortableList>
        </div>
      </template>
      <template v-slot:pagination="{ loadMoreItems }">
        <MRow>
          <MCol class="text-right">
            <a @click.prevent="loadMoreItems">
              {{ $t('load_more') }}
            </a>
          </MCol>
        </MRow>
      </template>
    </FlotoCrudContainer>
  </div>
</template>

<script>
import {
  removeCategoryApi,
  updateCategoriesApi,
  updateCategoryApi,
  createCategoryApi,
  searchCategoriesApi,
} from '@modules/category/category-api'
import SortableList from '@components/sortable/sortable-list'
import { authComputed } from '@state/modules/auth'
import ServiceCategoryItem from './service-category-item'
import ServiceCategoryForm from './service-category-form'
export default {
  name: 'CategorySelector',
  components: {
    ServiceCategoryItem,
    SortableList,
    ServiceCategoryForm,
  },
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  data() {
    this.moduleName = this.$constants.SERVICE_CATALOG
    return {
      query: '',
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    setActiveFolder(category) {
      this.$emit('change', category)
    },
    getCategories(limit, offset) {
      return searchCategoriesApi(
        this.moduleName,
        { name: this.query, isPublic: this.isPortalLogin },
        limit,
        offset
      ).then((data) => {
        this.$emit('categories-loaded', data)
        return data
      })
    },
    createFn(data) {
      return createCategoryApi(this.moduleName, data)
    },
    handleBulkUpdate(event, replaceAllItems) {
      replaceAllItems(event.items)
      const newData = event.items.map((i, index) => ({
        ...i,
        order: index + 1,
      }))
      const updatedIds = event.updatedItems.map(({ id }) => id)
      const updatedData = newData.filter((i) => updatedIds.indexOf(i.id) >= 0)
      updateCategoriesApi(this.moduleName, updatedData).catch(() => {
        replaceAllItems(event.oldData)
      })
    },
    updateFn(category) {
      return updateCategoryApi(this.moduleName, category)
    },
    removeFn(category) {
      return removeCategoryApi(this.moduleName, category)
    },
    handleRemoveFolder(item, removeFn, replaceFn) {
      return removeFn(item).then(() => {
        searchCategoriesApi(this.moduleName, {}, 1).then((data) => {
          const trashFolder = data.items.find((f) => f.isTrash)
          if (trashFolder) {
            replaceFn(trashFolder)
          }
        })
      })
    },
    handleNewItemsAdded(items, addFn) {
      items.forEach((i) => addFn(i))
    },
  },
}
</script>
