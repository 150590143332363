import api from '@api'
import { transformTask, transformTaskForServer } from '@data/task'

import {
  getRootPluaralTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
export function searchMyTasksApi(qualifications, limit, offset) {
  return api
    .post(
      `/mytasks`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformTask),
        total: data.totalCount,
      }
    })
}
export function getMyTaskApi(id) {
  return api.get(`/task/${id}`).then(transformTask)
}

export function getMyTaskupdateApi({ id, ...change }) {
  return api
    .patch(`task/${id}`, transformTaskForServer(change), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('task'),
      }),
    })
    .then(transformTask)
}

export function getMyTasksByStatusIdsApi(
  statusId,
  qualifications,
  limit,
  offset
) {
  return api
    .post(
      `/mytasks/${statusId}`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformTask),
        total: data.totalCount,
      }
    })
}
