import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
  // getModuleTranslator,
} from '@utils/get-module-translator'
import { transformAssetForList } from '@data/asset'
import {
  transformSoftwareLicense,
  transformSoftwareLicenseForServer,
} from '@data/software-license'
// import config from './config'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
// const __t = getModuleTranslator(config.translationKey)

export function searchByQualApi(qualifications, limit, offset, params = {}) {
  return api
    .post(
      `/asset/softwareLicense/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformSoftwareLicense),
        total: data.totalCount,
      }
    })
}

export function addSoftwareLicenseApi(data) {
  return api
    .post(`/asset/softwareLicense`, transformSoftwareLicenseForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('software_license'),
      }),
    })
    .then(transformSoftwareLicense)
}

export function getSoftwareLicenseApi(id) {
  return api.get(`/asset/softwareLicense/${id}`).then(transformSoftwareLicense)
}

export function updateSoftwareLicenseApi(data, message) {
  return api
    .patch(
      `/asset/softwareLicense/${data.id}`,
      transformSoftwareLicenseForServer(data),
      {
        message:
          message ||
          __rootT('updated_successfully', {
            resource: __rootTc('software_license'),
          }),
      }
    )
    .then(transformSoftwareLicense)
}

export function deleteSoftwareLicenseApi(id) {
  return api.delete(`/asset/softwareLicense/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('software_license'),
    }),
  })
}

export function bulkDeleteApi(ids) {
  return api.delete(`/asset/softwareLicense/bulk/delete`, {
    data: {
      userIds: ids,
    },
    message: __rootT('archived_successfully', {
      resource: __rootTc('software_license', 2),
    }),
  })
}

export function searchSoftwareLicenseInstallationApi(
  id,
  qualifications,
  limit,
  offset,
  additionalUrlParams = {}
) {
  return api
    .post(
      `/asset/softwareLicense/installation/${id}/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalUrlParams,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
        total: data.totalCount,
      }
    })
}
