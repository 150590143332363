<template>
  <MRow :gutter="0" class="mt-4">
    <MCol id="status-picker" :size="3">
      <FlotoStatusPicker
        module-name="task"
        :disabled="disabled"
        :value="value.statusId"
        :placeholder="disabled ? '---' : undefined"
        :allowed-status="
          isProjectMilestoneTask ? ['open', 'closed', 'cancelled'] : []
        "
        @change="handleChange({ statusId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            v-bind="slotData"
            :lable="$t('status')"
            icon-name="status"
            :disabled="disabled"
          />
        </template>
      </FlotoStatusPicker>
    </MCol>
    <MCol id="priority-picker" :size="3">
      <FlotoPriorityPicker
        :disabled="disabled"
        :placeholder="disabled ? '---' : undefined"
        :value="value.priorityId"
        @change="handleChange({ priorityId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            v-bind="slotData"
            :lable="$t('priority')"
            icon-name="priority"
            :disabled="disabled"
          />
        </template>
      </FlotoPriorityPicker>
    </MCol>
    <MCol id="task-type-picker" :size="3">
      <FlotoTaskTypePicker
        :value="value.taskTypeId"
        :disabled="disabled || isProjectMilestoneTask"
        :placeholder="disabled ? '---' : undefined"
        @change="handleChange({ taskTypeId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            v-bind="slotData"
            :lable="$tc('task_type')"
            icon-name="file-check"
            :disabled="disabled || isProjectMilestoneTask"
          />
        </template>
      </FlotoTaskTypePicker>
    </MCol>
    <MCol
      v-if="
        moduleName !== $constants.CHANGE && moduleName !== $constants.RELEASE
      "
      id="start-end-date-picker"
      :size="3"
    >
      <FlotoTriggerView
        icon-name="calendar-alt"
        :lable="`${$tc('start')} ${$tc('date')}`"
        :value="
          !(value.timeRange || {}).startTime
            ? '---'
            : value.timeRange.startTime | datetime
        "
      />
    </MCol>
  </MRow>
</template>

<script>
import { TaskComputed } from '@state/modules/task'
export default {
  name: 'PrimaryRow',
  model: {
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Object, required: true },
    moduleName: { type: String, required: true },
  },
  computed: {
    ...TaskComputed,
    isProjectMilestoneTask() {
      const taskTypeId = (
        this.taskTypeOptions.find((t) => t.systemName === 'Milestone') || {}
      ).id
      if (
        this.moduleName === this.$constants.PROJECT &&
        this.value.taskTypeId === taskTypeId
      ) {
        return true
      }
      return false
    },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>
