<template>
  <div
    class="flex flex-col flex-1 counter-steps"
    :class="{
      'px-16': moduleName === $constants.CHANGE,
      'px-2': moduleName === $constants.RELEASE,
    }"
  >
    <MSteps :active="activeStage" :status="currentStepStatus">
      <MStep v-for="stage in stages" :key="stage.key">
        <template v-slot:title>
          <CounterStep
            :tooltip-text="stage.text"
            :status="currentStage.key === 'close' ? 'success' : stage.status"
          >
            <MIcon :name="stage.icon" size="lg" />
          </CounterStep>
        </template>
        <template v-slot:description>
          <StageActionInfo
            v-bind="$attrs"
            :stage="stage"
            :resource="resource"
            :module-name="moduleName"
            :completed="stage.status === 'success'"
            :pending="stage.status === 'pending'"
            :running="stage.status === 'progress' || stage.status === 'error'"
            @change="handleChange"
          />
        </template>
      </MStep>
    </MSteps>
    <FlotoConfirmModal
      :open="showConfirmMoveStageModal"
      @hide="showConfirmMoveStageModal = false"
      @confirm="handleConfirmAction"
    >
      <template v-slot:icon>
        <MIcon
          :name="currentStage.icon"
          class="w-full text-primary"
          size="2x"
        />
      </template>
      <template v-slot:message>
        {{
          $t('confirm_move_stage', {
            stage: currentStage.text,
          })
        }}
      </template>
    </FlotoConfirmModal>
  </div>
</template>

<script>
import { StatusComputed } from '@state/modules/status'
import CounterStep from '@components/counter-step'
import { takeConfirmationOnStageChange } from '@modules/ticket/helpers/change-stage-tabs'
import StageActionInfo from './stage-action-info'
export default {
  name: 'ChangeStagesContainer',
  components: { CounterStep, StageActionInfo },
  props: {
    resource: { type: Object, required: true },
    moduleName: { type: String, required: true },
    updateApi: { type: Function, default: undefined },
  },
  data() {
    return {
      showConfirmMoveStageModal: false,
      pendingChange: null,
    }
  },
  computed: {
    ...StatusComputed,
    initialStages() {
      return [
        {
          text: this.$t('submitted'),
          key: 'submitted',
          id: 0,
          status: 'progress',
          icon: 'file-import',
        },
        {
          text: this.$t('planning'),
          key: 'planning',
          id: 1,
          status: 'pending',
          icon: 'planning',
        },
        {
          text: this.$tc('approval'),
          key: 'approval',
          id: 2,
          status: 'pending',
          icon: 'file-check',
        },
        ...(this.moduleName === this.$constants.CHANGE
          ? [
              {
                text: this.$t('implementation'),
                key: 'implementation',
                id: 3,
                status: 'pending',
                icon: 'cogs',
              },
            ]
          : []),
        ...(this.moduleName === this.$constants.RELEASE
          ? [
              {
                text: this.$t('build'),
                key: 'build',
                id: 3,
                status: 'pending',
                icon: 'cogs',
              },
              {
                text: this.$t('testing'),
                key: 'testing',
                id: 4,
                status: 'pending',
                icon: 'clipboard-list-check',
              },
              {
                text: this.$tc('deployment'),
                key: 'deployment',
                id: 5,
                status: 'pending',
                icon: 'rocket',
              },
            ]
          : []),
        {
          text: this.$t('in_review'),
          key: 'review',
          id: 6,
          status: 'pending',
          icon: 'comments',
        },
        {
          text: this.$t('closed'),
          key: 'close',
          id: 7,
          status: 'pending',
          icon: 'check',
          // icon: 'window-close',
        },
      ]
    },
    activeStage() {
      return this.currentStage.id
    },
    resourceStatus() {
      if (this.resource.prevStatusId) {
        return this[`${this.moduleName}FindStatusFromId`](
          this.resource.prevStatusId
        )
      }
      if (this.resource.statusId) {
        return this[`${this.moduleName}FindStatusFromId`](
          this.resource.statusId
        )
      }
      return undefined
    },
    currentStage() {
      if (this.resourceStatus) {
        return this.initialStages.find(
          (s) => s.key === this.resourceStatus.stage
        )
      }
      return {}
    },
    stages() {
      return this.getStages(this.currentStage)
    },
    currentStepStatus() {
      if (this.resource.prevStatusId) {
        return 'error'
      }
      if (['close'].indexOf(this.currentStage.key) >= 0) {
        return 'finish'
      }
      if (this.currentStage.status === 'progress') {
        return 'process'
      }
      if (this.currentStage.status === 'rejected') {
        return 'error'
      }
      return 'process'
    },
  },
  methods: {
    handleChange(data) {
      if ('statusId' in data) {
        const status = this[`${this.moduleName}FindStatusFromId`](data.statusId)
        if (
          takeConfirmationOnStageChange.indexOf(status.name.toLowerCase()) >= 0
        ) {
          this.showConfirmMoveStageModal = true
          this.pendingChange = data
          return Promise.resolve()
        }
      }
      return this.updateApi({ id: this.resource.id, ...data }).then(() => {
        this.pendingChange = null
        this.$emit('refresh')
      })
    },
    handleConfirmAction() {
      return this.updateApi({
        id: this.resource.id,
        ...this.pendingChange,
      }).then(() => {
        this.pendingChange = null
        this.$emit('refresh')
      })
    },
    getStages(currentStage) {
      const index = this.initialStages
        .map((i) => i.key)
        .indexOf(currentStage.key)
      if (index !== -1) {
        const stages = [
          ...this.initialStages
            .slice(0, index)
            .map((s) => ({ ...s, status: 'success' })),
          {
            ...currentStage,
            status: this.resource.prevStatusId ? 'error' : 'progress',
          },
          ...this.initialStages
            .slice(index + 1)
            .map((s) => ({ ...s, status: 'pending' })),
        ]
        return stages
      }
      return this.initialStages
    },
  },
}
</script>
