var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col service-catalog-item mb-4 p-2 rounded bg-neutral-lightest",class:{ active: _vm.active }},[_c(_vm.avoidRedirection ? 'div' : _vm.disabled ? 'FlotoLink' : 'div',_vm._b({tag:"component",staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('service-click', _vm.service)}}},'component',
      _vm.disabled
        ? {
            to: _vm.$modules.getModuleRoute(
              _vm.isPortalLogin ? 'support-portal' : 'service-catalog',
              'request-service',
              {
                params: { id: _vm.service.id },
              }
            ),
          }
        : {}
    ,false),[(!_vm.disabled)?_c('HoverAction',{staticClass:"flex justify-end",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var stickAction = ref.stickAction;
    var unstickAction = ref.unstickAction;
return [_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{on:{"click":function($event){return _vm.$emit('clone')}}},[_c('MIcon',{staticClass:"text-neutral-light",attrs:{"id":("duplicate-btn-" + (_vm.service.name)),"name":"clone"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$tc('duplicate'))+" ")]),_c('FlotoLink',{staticClass:"mx-1",attrs:{"to":_vm.$modules.getModuleRoute(
              _vm.isPortalLogin ? 'support-portal' : 'service-catalog',
              'edit',
              {
                params: { id: _vm.service.id },
              }
            )}},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"text-neutral-light",attrs:{"id":("edit-btn-" + (_vm.service.name)),"name":"pencil"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])],1),_c('FlotoDeleteBtn',{attrs:{"message":_vm.$t('confirm_delete_item', { item: _vm.$tc('service') })},on:{"confirm":function($event){return _vm.$emit('remove')},"trigger":function($event){return _vm.persistHover(stickAction)},"cancel":function($event){return _vm.unpersistHover(unstickAction)}}},[_c('MIcon',{staticClass:"text-neutral-light",attrs:{"id":("delete-btn-" + (_vm.service.name)),"name":"times-circle"}})],1)]}}],null,false,2780066159)}):_vm._e(),_c(_vm.avoidRedirection ? 'div' : !_vm.disabled ? 'FlotoLink' : 'div',_vm._b({tag:"component",staticClass:"flex items-center justify-center mb-2",class:{ 'mt-2': _vm.disabled }},'component',
        !_vm.disabled
          ? {
              to: _vm.$modules.getModuleRoute(
                _vm.isPortalLogin ? 'support-portal' : 'service-catalog',
                'edit',
                {
                  params: { id: _vm.service.id },
                }
              ),
            }
          : {}
      ,false),[_c('img',{attrs:{"src":_vm.service.image,"height":"100","width":"100"}})]),_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('div',{staticClass:"w-full flex flex-col"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('h5',{staticClass:"m-0 text-ellipsis text-center"},[_vm._v(_vm._s(_vm.service.name))])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.service.name)+" ")]),_c('small',{staticClass:"text-neutral-light text-ellipsis text-center"},[_vm._v(" "+_vm._s(_vm.categoryName)+" ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }