<template>
  <FlotoContentLoader :loading="loading">
    <MRow :gutter="16">
      <MCol
        :class="{
          'bg-neutral-lightest': giveExternalUpdateOptoin,
          'py-2 px-6': giveExternalUpdateOptoin,
          rounded: giveExternalUpdateOptoin,
        }"
      >
        <MRow>
          <MCol
            v-if="giveExternalUpdateOptoin"
            :size="12"
            style="justify-content: flex-end"
            class="flex"
          >
            <MButton
              :disabled="disabled || service.archived"
              variant="neutral-light"
              class="mx-1"
              :shadow="false"
              shape="circle"
              @click="handleEditCustomField"
            >
              <MIcon name="pencil" />
            </MButton>
          </MCol>
          <MCol auto-size>
            <img :src="service.image" height="150" width="150" />
          </MCol>
          <MCol class="flex-1">
            <h5 class="text-primary">
              {{ service.name }}
              {{ service.archived ? `(${$t('archived')})` : '' }}
            </h5>
            <div
              v-if="
                (!isPortalLogin && service.amount > 0) ||
                (isPortalLogin && service.showInCustomerPortalAmount)
              "
            >
              {{ $tc('cost') }} : {{ service.amount }}
              {{ organization.currency }}
            </div>
            <div>
              {{ service.serviceDescription }}
            </div>
            <div>
              {{ serviceCatagoryName }}
            </div>
          </MCol>
        </MRow>
        <MRow class="mt-4">
          <MCol :size="8">
            <FormConsumer
              v-if="form.id && form.fields.length"
              :value="resource"
              :form-fields="formFields"
              :disabled="additionalInfoDisabled"
              :avoid-default-value="true"
              @submit="handleFormSubmitted"
            >
              <template v-slot:submit>
                <MButton :loading="processing" type="submit">
                  {{ $t('update') }}
                </MButton>
              </template>
              <template v-slot:reset>
                <span />
              </template>
            </FormConsumer>
          </MCol>
        </MRow>
      </MCol>
    </MRow>
  </FlotoContentLoader>
</template>

<script>
import Pick from 'lodash/pick'
import { authComputed } from '@state/modules/auth'
import { OrganizationComputed } from '@state/modules/organization'
import { getServiceCatalogApi } from '@modules/service-catalog/service-catalog-api'
import { getFormApi } from '@modules/form/form-api'
import { CategoryComputed } from '@state/modules/category'
import FormConsumer from '@components/form-consumer.vue'
import { flattenFields } from '@data/form'
import { getFieldByRequesterAccess } from '@modules/support-portal/helpers/field-access'
import { updateApi } from '../ticket-api'

export default {
  name: 'RequestInformationTab',
  components: { FormConsumer },
  props: {
    resource: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    isApproval: { type: Boolean, default: false },
    isFormRulesAvailable: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      processing: false,
      service: {},
      form: {},
    }
  },
  computed: {
    ...authComputed,
    ...OrganizationComputed,
    ...CategoryComputed,
    serviceCatagoryName() {
      const item = this.serviceCatalogCategories.find(
        (c) => this.service.categoryId === c.id
      )
      if (item && item.name) {
        return item.name
      }
      return null
    },
    formFields() {
      const fields = (this.form.fields || []).filter(
        (f) => !f.isSystemField && f.inputType !== 'api'
      )
      if (this.isPortalLogin) {
        if (this.isApproval) {
          return fields.filter((f) => f.useOnPortal)
        }
        return getFieldByRequesterAccess(
          fields.filter((f) => f.useOnPortal),
          this.user
        )
      }
      return fields
    },
    additionalInfoDisabled() {
      if (this.disabled || this.service.archived) {
        return true
      }
      return this.isFormRulesAvailable
    },
    giveExternalUpdateOptoin() {
      return (
        !this.disabled && !this.service.archived && this.isFormRulesAvailable
      )
    },
  },
  created() {
    this.getService()
  },
  methods: {
    getService() {
      getServiceCatalogApi(
        this.resource.serviceCatalogId,
        {
          archived: true,
        },
        this.isPortalLogin
      ).then((data) => {
        this.service = data
        this.getServiceForm(data.archived)
        this.loading = false
      })
    },
    getServiceForm(isArchived = false) {
      getFormApi(
        this.$constants.SERVICE_CATALOG,
        this.service.id,
        {
          archived: isArchived,
        },
        this.isPortalLogin
      ).then((data) => {
        this.form = data
      })
    },
    handleFormSubmitted(update) {
      this.processing = true
      const fieldIds = flattenFields(this.form.fields).map((f) => f.id)
      updateApi(
        this.$constants.REQUEST,
        {
          id: this.resource.id,
          ...Pick(update, fieldIds),
        },
        this.isPortalLogin
      )
        .then(() => {
          this.$emit('refresh')
        })
        .finally(() => (this.processing = false))
    },
    handleEditCustomField() {
      if (!this.disabled && !this.service.archived) {
        this.$emit('edit-request-info-tab')
      }
    },
  },
}
</script>
