import api from '@api'
import {
  transformTicketForList,
  transformTicketForDetail,
  transformTicketForServer,
} from '@data/ticket'
import { successToast } from '@motadata/ui'
import { transformAvailableColumn } from '@data/report'
import {
  getRootTranslator,
  getRootPluaralTranslator,
  getModuleTranslator,
} from '@/src/utils/get-module-translator'

import { transformTransition } from '@data/audit'

import config from './config'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
const __moduleTc = getModuleTranslator(config.translationKey)

export function searchTicketsApi(
  moduleName,
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/${moduleName}/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformTicketForDetail),
        total: data.totalCount,
      }
    })
}

export function getApi(moduleName, id, isPublic) {
  return api
    .get(`${isPublic ? '/cportal' : ''}/${moduleName}/${id}`)
    .then(transformTicketForDetail)
}

export function updateApi(moduleName, { id, ...change }, isPublic) {
  return api
    .patch(
      `${isPublic ? '/cportal' : ''}/${moduleName}/${id}`,
      transformTicketForServer(change),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc(moduleName, 1),
        }),
      }
    )
    .then(transformTicketForDetail)
}

export function createApi(
  moduleName,
  request,
  isPublic,
  additionalParams = {}
) {
  return api.post(
    `${isPublic ? '/public' : ''}/${moduleName}`,
    transformTicketForServer(request),
    {
      params: {
        ...additionalParams,
      },
      message: __rootT('created_successfully', {
        resource: __rootTc(moduleName, 1),
      }),
    }
  )
}

export function createSplitRequestApi(moduleName, request, parentId) {
  return api.post(
    `/${moduleName}/split/${parentId}`,
    transformTicketForServer(request),
    {
      message: __rootT('created_successfully', {
        resource: __rootTc(moduleName, 1),
      }),
    }
  )
}

export function sendFeedbackApi(id) {
  return api.get(`/request/${id}/sendfeedback`, {
    message: __rootT('sent_successfully', { resource: __rootTc('feedback') }),
  })
}

export function bulkUpdateApi(moduleName, ids, data) {
  const payload = {
    objectIds: ids,
    updateMap: transformTicketForServer(data),
  }
  return api
    .patch(`/${moduleName}/bulk/update`, payload, {
      notify: false,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${data.successIds.length} ${__rootT(
            data.successIds.length > 1
              ? 'updated_successfully_multiple'
              : 'updated_successfully',
            {
              resource: __rootTc(moduleName, data.successIds.length),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function archiveApi(moduleName, id) {
  return api.delete(`/${moduleName}/delete/${id}`, {
    message: __rootT('archived_successfully', {
      resource: __rootTc(moduleName),
    }),
  })
}

export function bulkDeleteApi(moduleName, ids) {
  return api
    .delete(`/${moduleName}/bulk/delete`, {
      data: { objectIds: ids },
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${data.successIds.length} ${__rootT(
            data.successIds.length > 1
              ? 'archived_successfully_multiple'
              : 'archived_successfully',
            {
              resource: __rootTc(moduleName, data.successIds.length),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function mergeRequestApi(
  primaryRequestId,
  secondaryRequestIds,
  primaryNote,
  secondaryNote
) {
  return api.post(
    `request/merge`,
    {
      primaryTicketId: primaryRequestId,
      mergeTicketIds: secondaryRequestIds,
      primaryComment: primaryNote,
      secondaryComment: secondaryNote,
    },
    {
      message: __moduleTc('merged_successfully'),
    }
  )
}

export function getRequestsForUserApi(userId, limit, offset) {
  return api
    .get(`/request/history/${userId}`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformTicketForList),
        total: data.totalCount,
      }
    })
}

export function bulkUnMarkAsSpamApi(ids) {
  return api.post(
    `/request/bulk/unspam`,
    { objectIds: ids },
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('request', ids.length),
      }),
    }
  )
}

export function bulkUnarchiveApi(moduleName, ids) {
  return api.patch(
    `/${moduleName}/bulk/unarchive`,
    { objectIds: ids },
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc(moduleName, ids.length),
      }),
    }
  )
}

export function bulkPermanentDeleteApi(moduleName, ids) {
  return api.delete(`/${moduleName}/bulk/permanentdelete`, {
    data: { objectIds: ids },
    message: __rootT('deleted_successfully', {
      resource: __rootTc(moduleName, ids.length),
    }),
  })
}

export function getTaskResourceDetailApi(moduleName, id, isPublic) {
  return api.get(`/${moduleName}/ref/task/${id}`).then(transformTicketForDetail)
}

export function getExportAvailableColumnsApi(
  moduleName,
  additionalParams = {}
) {
  return api
    .get(`analytics/export/${moduleName}/supporteddata`, {
      params: {
        ...additionalParams,
      },
    })
    .then((data) => {
      const transformedData = {}
      transformedData.exportColumns = data.exportColumns.map(
        transformAvailableColumn
      )
      return transformedData
    })
}

export function exportDataApi(data, message) {
  return api.post(
    '/analytics/export/griddata',
    data,
    {
      message:
        message ||
        __rootT('export_successfully', {
          resource: __rootTc(data.model),
        }),
    },
    {
      notification: {
        error: {
          message: __rootT('error'),
        },
      },
    }
  )
}

export function getRequestDataFromAPIFieldApi(data, fieldId) {
  return api
    .post(`/public/apifield/request/${fieldId}`, data, { notify: false })
    .then((response) => {
      return {
        ...transformTicketForDetail(response),
        apiResponseMessage: response.apiResponseMessage,
        apiSuccess: response.apiSuccess,
      }
    })
}

export function getTransitionDataApi(
  moduleName,
  id,
  transitionType,
  data = {}
) {
  return api
    .post(`/${moduleName}/${id}/transition/${transitionType}`, data, {
      notify: false,
    })
    .then((response) => {
      return {
        data: response.horizontalData,
        items: (response.transitionList || []).map(transformTransition),
        total: response.totalCount,
      }
    })
}

export function getChangeScheduleConflictApi(moduleName, data, limit, offset) {
  return api
    .post(`/${moduleName}/scheduleconflict`, data, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
      notification: {
        error: {
          message: __rootT('error'),
        },
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformTicketForDetail),
        total: data.totalCount,
      }
    })
}

export function getTicketsByStatusIdApi(
  statusId,
  moduleName,
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/${moduleName}/search/byqual/${statusId}`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformTicketForDetail),
        total: data.totalCount,
      }
    })
}
