import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
  getModuleTranslator,
} from '@utils/get-module-translator'
import {
  buildNameFilterQuery,
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformUserForList,
  transformUser,
  transformUserForServer,
  transformSuperAdminForServer,
  transformUserForVuex,
} from '@data/user'
import { isPortalLogin } from '@utils/auth'
import config from './config'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
const __t = getModuleTranslator(config.translationKey)

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(buildNameFilterQuery(qualificationFactors.name))
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(buildNameFilterQuery(qualificationFactors.selectedName, 'in'))
  }
  return buildFlatQualificationStructure(quals)
}

const verifiedQuery = (value) =>
  buildRelationalQualificationStructure(
    'verified',
    'equal',
    value,
    'boolean',
    'db'
  )

const deletedQuery = (value) =>
  buildRelationalQualificationStructure(
    'removed',
    'equal',
    value,
    'boolean',
    'db'
  )

export function getUsersApi(
  type,
  filters,
  searchCriteria,
  limit,
  offset,
  params = {}
) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  if ('name' in filters && filters.name) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('selectedName' in filters && filters.selectedName) {
    qualDetails.push(buildNameFilterQuery(filters.selectedName))
  }
  if ('verified' in filters) {
    qualDetails.push(verifiedQuery(true))
  }
  if ('unverified' in filters) {
    qualDetails.push(verifiedQuery(false))
  }
  if ('archived' in filters) {
    qualDetails.push(deletedQuery(true))
  }
  if ('archivedAndUnarchived' in filters) {
    qualDetails.push(deletedQuery(true))
    qualDetails.push(deletedQuery(false))
  }
  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  if ('excludedIds' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.excludedIds,
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      `${type}/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserForList),
        total: data.totalCount,
      }
    })
}

export function getUsersPublicApi(
  type,
  filters,
  searchCriteria,
  limit,
  offset,
  params = {}
) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  if ('name' in filters && filters.name) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('selectedName' in filters && filters.selectedName) {
    qualDetails.push(buildNameFilterQuery(filters.selectedName))
  }
  if ('verified' in filters) {
    qualDetails.push(verifiedQuery(true))
  }
  if ('unverified' in filters) {
    qualDetails.push(verifiedQuery(false))
  }
  if ('archived' in filters) {
    qualDetails.push(deletedQuery(true))
  }
  if ('archivedAndUnarchived' in filters) {
    qualDetails.push(deletedQuery(true))
    qualDetails.push(deletedQuery(false))
  }
  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      `public/user/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserForList),
        total: data.totalCount,
      }
    })
}

export function getRequestersApi(filters, limit, offset, params = {}) {
  const qualDetails = []
  if ('name' in filters && filters.name) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('selectedName' in filters && filters.selectedName) {
    if (Array.isArray(filters.selectedName)) {
      qualDetails.push(buildNameFilterQuery(filters.selectedName, 'in'))
    } else {
      qualDetails.push(buildNameFilterQuery(filters.selectedName))
    }
  }
  if ('archivedAndUnarchived' in filters) {
    qualDetails.push(deletedQuery(true))
    qualDetails.push(deletedQuery(false))
  }
  if ('ids' in filters && filters.ids.length) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  if ('allowToLogin' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'allowToLogin',
        'equal',
        filters.allowToLogin,
        'boolean',
        'db'
      )
    )
  }
  if ('excludedIds' in filters && filters.excludedIds.length) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.excludedIds,
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      'requester/search',
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserForList),
        total: data.totalCount,
      }
    })
}

export function restoreUsersApi(type, users) {
  return api.patch(
    `${type}/bulk/restore`,
    {
      userIds: users,
    },
    {
      message: __rootT('restored_successfully', {
        resource: __rootTc(type),
      }),
    }
  )
}

export function createUserApi(type, data) {
  return api
    .post(`${type}`, transformUserForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc(type),
      }),
    })
    .then(transformUser)
}

export function changeSuperAdmin(data) {
  return api.post('/convert/superadmin', transformSuperAdminForServer(data), {
    message: __rootT('changed_successfully', {
      resource: __rootTc('super_admin'),
    }),
  })
}

export function getUserApi(type, id, params = {}) {
  return api.get(`${type}/${id}`, params).then(transformUser)
}
export function unLockUserApi(id) {
  return api.get(`/user/${id}/unlock`)
}

export function getGlobalUserApi(id, additionaParams = {}) {
  return api
    .get(`user/${id}`, {
      params: {
        ...additionaParams,
      },
    })
    .then(transformUser)
}

export function updateUserApi(type, data, message) {
  return api
    .patch(
      `${isPortalLogin() ? '/cportal' : ''}/${type}${
        isPortalLogin() ? '' : `/${data.id}`
      }`,
      transformUserForServer(data),
      {
        message:
          message ||
          __rootT('updated_successfully', {
            resource: __rootTc(type),
          }),
      }
    )
    .then(transformUser)
}

export function convertUserApi(type, data) {
  return api.get(`/user/${data.id}/convert/${data.userType}`, {
    message: __rootT('updated_successfully', {
      resource: __rootTc(type),
    }),
  })
}

export function deleteUserApi(type, id) {
  return api.delete(`${type}/${id}`, {
    message: __rootT('archived_successfully', {
      resource: __rootTc(type),
    }),
  })
}

export function bulkDeleteUserApi(type, ids) {
  return api.delete(`${type}/bulk/delete`, {
    data: {
      userIds: ids,
    },
    message: __rootT('archived_successfully', {
      resource: __rootTc(type, 2),
    }),
  })
}

export function sendVerificationEmailApi(id) {
  return api.get(`/requester/sendactivation/email/${id}`, {
    message: __t('email_sent_successfully'),
  })
}

export function searchUserByEmailApi(value, isEmail) {
  const emailParam = isEmail ? '&email=true' : '&email=false'
  return api
    .get(
      `/requester/search/email?fullObject=true&query=${encodeURIComponent(
        value
      )}${emailParam}`
    )
    .then((data) => (data || []).map(transformUserForVuex))
}

export function exportUsersApi(filters, searchCriteria, data) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  if ('name' in filters) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('verified' in filters) {
    qualDetails.push(verifiedQuery(true))
  }
  if ('unverified' in filters) {
    qualDetails.push(verifiedQuery(false))
  }
  if ('archived' in filters) {
    qualDetails.push(deletedQuery(true))
  }
  return api.post(
    '/analytics/export/griddata',
    {
      ...data,
      qualification: qualDetails.length
        ? buildFlatQualificationStructure(qualDetails)
        : undefined,
    },
    {
      message: __rootT('export_successfully', {
        resource: __rootTc(data.model),
      }),
    },
    {
      notification: {
        error: {
          message: __rootT('error'),
        },
      },
    }
  )
}

export function getManagersApi(userId, filter, limit, offset, urlParams = {}) {
  return api
    .post(
      `/user/manager/${userId}`,
      {
        qualDetails:
          filter.selectedName || filter.name
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...urlParams,
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList || [],
        total: data.totalCount,
      }
    })
}

export function getActiveTechniciansApi() {
  return api.get('/technician/active/list')
}
