<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
export default {
  name: 'MyTasksModule',
  page() {
    return {
      title: this.$t('my_tasks'),
    }
  },
}
</script>
