<template>
  <FlotoDrawerForm
    :open="open"
    width="65%"
    @cancel="$emit('cancel')"
    @submit="handleSubmit"
  >
    <template v-slot:header>
      <slot name="title"> {{ $t('edit') }} {{ formData.name }} </slot>
    </template>
    <component
      :is="isPortalLogin ? 'SupportPortalTicketForm' : 'TicketForm'"
      :value="formData"
      :module-name="$constants.REQUEST"
      :processing="processing"
      :consider-priority-matrix="false"
      :use-template="false"
      :use-link-asset="false"
      :with-submit="false"
      :use-requester="false"
      :avoid-default-value="Boolean(formData.id)"
      :additional-custom-form-fields="additionalCustomFormFields"
      @change="handleChange"
    />
    <template v-slot:actions="{ hide, submit }">
      <MButton
        variant="primary"
        class="mr-2"
        :loading="processing"
        @click="submit"
      >
        {{ $t('submit') }}
      </MButton>
      <MButton variant="default" @click="hide">
        {{ $t('cancel') }}
      </MButton>
    </template>
  </FlotoDrawerForm>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import TicketForm from '@modules/ticket/components/ticket-form'
import SupportPortalTicketForm from '@modules/support-portal/components/support-portal-ticket-form'
import { executionTypeMap, userTypeMap, eventTypeMap } from '@data/form-rules'
export default {
  name: 'TicketFormEditModal',
  components: { TicketForm, SupportPortalTicketForm },
  inject: {
    formRulesContext: {
      default: () => ({
        isFormRuleQualified: () => {},
      }),
    },
  },
  props: {
    open: { type: Boolean, default: false },
    submitFn: { type: Function, required: true },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      width: window.innerWidth - 300,
      formData: { ...this.resource },
      processing: false,
    }
  },
  computed: {
    ...authComputed,
    formRulesUserTypeFilter() {
      if (!this.loggedIn) {
        return [userTypeMap['all'], userTypeMap['requester']]
      }
      if (this.isPortalLogin) {
        return [
          userTypeMap['all'],
          userTypeMap['requester'],
          userTypeMap['logged_in_user'],
        ]
      }
      return [
        userTypeMap['all'],
        userTypeMap['technician'],
        userTypeMap['logged_in_user'],
      ]
    },
    additionalCustomFormFields() {
      return this.formRulesContext.hasServiceCatalogFormRules
        ? this.formRulesContext.serviceCatalogFormFields
        : []
    },
  },
  methods: {
    handleChange(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    handleSubmit() {
      this.processing = true
      let updatedData = this.formData
      if (
        this.formRulesContext.isFormRuleQualified({
          executionTypes: [
            executionTypeMap['create_and_edit'],
            ...(this.formData.id
              ? [executionTypeMap['edit']]
              : [executionTypeMap['create']]),
          ],
          userTypes: this.formRulesUserTypeFilter,
          eventType: eventTypeMap['submit'],
          requesterDetails: this.requesterDetails,
        })
      ) {
        this.formRulesContext.executeFormRules(this.formData, {
          executionTypes: [
            executionTypeMap['create_and_edit'],
            ...(this.formData.id
              ? [executionTypeMap['edit']]
              : [executionTypeMap['create']]),
          ],
          userTypes: this.formRulesUserTypeFilter,
          eventType: eventTypeMap['submit'],
          requesterDetails: this.requesterDetails,
        })
        const formData = this.formRulesContext.updatedResource
        const fieldRulesState = this.formRulesContext.fieldRulesState
        const overridedFieldValue = {}
        Object.keys(fieldRulesState).map((key) => {
          const fieldKey = /^\d+$/.test(key) ? +key : key
          if (
            fieldRulesState[fieldKey] &&
            'setValue' in fieldRulesState[fieldKey]
          ) {
            overridedFieldValue[fieldKey] = fieldRulesState[fieldKey].setValue
          }
          if (
            fieldRulesState[fieldKey] &&
            'clearValue' in fieldRulesState[fieldKey]
          ) {
            overridedFieldValue[fieldKey] = fieldRulesState[fieldKey].clearValue
          }
        })
        updatedData = { ...formData, ...overridedFieldValue }
      }
      this.submitFn(updatedData).finally(() => {
        this.processing = false
      })
    },
  },
}
</script>
