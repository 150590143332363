<template>
  <MRow :gutter="0" class="mb-4 rounded">
    <MCol class="rounded px-2 m-border">
      <div class="my-2 flex items-center mb-2">
        <h5
          class="flex-1 mb-0"
          :class="{
            'text-primary':
              moduleName !== $constants.CHANGE ||
              moduleName !== $constants.RELEASE,
            'text-netural-light':
              moduleName === $constants.CHANGE ||
              moduleName === $constants.RELEASE,
          }"
        >
          <slot name="title" />
        </h5>

        <MTooltip v-if="!disabled">
          <template v-slot:trigger>
            <MButton
              id="edit-close-btn"
              shape="circle"
              variant="transparent"
              :shadow="false"
              @click="toggleEditMode"
            >
              <MIcon
                :name="isEditing ? 'times-circle' : 'pencil'"
                :class="{
                  'text-neutral-light': !isEditing,
                  'text-secondary-red': isEditing,
                }"
              />
            </MButton>
          </template>
          {{ !isEditing ? $tc('edit') : $tc('cancel') }}
        </MTooltip>
      </div>
      <MDivider v-if="!isEditing && data.text" class="my-2" />
      <Transition name="placeholder">
        <div v-if="isEditing" class="flex flex-col">
          <FlotoForm
            layout="vertical"
            class="flex items-end w-full"
            @submit="handleSubmit"
          >
            <FlotoFormItem
              id="text-input"
              rules="required"
              class="w-full"
              :validation-label="$t('text')"
            >
              <FlotoRichEditor
                v-model="data.text"
                auto-focus
                :name="$t('text')"
                :rows="8"
                :affix-toolbar="false"
                :placeholder="$t('start_typing')"
                class="flex-1 mb-1"
              />
            </FlotoFormItem>
            <template v-slot:submit>
              <MButton
                id="submit-btn"
                :loading="processing"
                class="ml-4 mb-4"
                style="width: 50px; min-width: 50px; height: 50px;"
                shape="circle"
                type="submit"
              >
                <MIcon v-if="!processing" name="paper-plane" size="lg" />
                <span v-else />
              </MButton>
            </template>
          </FlotoForm>
          <MDivider class="mb-2" />
          <div class="mb-2">
            <FlotoAttachment v-model="data.fileAttachments" as-link />
          </div>
        </div>
        <MRow v-else>
          <MCol :size="12">
            <FlotoReadMore :height="8 * 20">
              <FlotoRichEditor :value="data.text" disabled />
            </FlotoReadMore>
          </MCol>
          <MCol :size="12">
            <FlotoAttachment v-model="data.fileAttachments" disabled />
          </MCol>
        </MRow>
      </Transition>
    </MCol>
  </MRow>
</template>

<script>
import { updateWorkApi } from '@components/conversation/api'

export default {
  name: 'WorkDetail',
  model: {
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Object, required: true },
    propKey: { type: String, required: true },
    parentId: { type: Number, required: true },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      isEditing: false,
      processing: false,
      fileList: [],
      data: {
        ...this.value,
      },
    }
  },
  watch: {
    value() {
      this.data = { ...this.value }
    },
  },
  methods: {
    toggleEditMode() {
      if (this.isEditing) {
        this.data = { ...this.value }
        this.isEditing = false
      } else {
        this.isEditing = true
      }
    },
    handleSubmit() {
      this.processing = true
      updateWorkApi(this.moduleName, this.parentId, this.data, this.propKey)
        .then((data) => {
          this.$emit('change', data[this.propKey])
          this.isEditing = false
        })
        .finally(() => (this.processing = false))
    },
  },
}
</script>
