<template>
  <MRow>
    <MCol :size="6">
      <h4 class="text-primary">{{ $t('schedule') }}</h4>
      <h5 class="text-netural-light text-sm">
        {{ $tc(moduleName) }} {{ $t('schedule') }}
      </h5>
      <MRow v-if="resource.changeScheduleDetected">
        <MCol :size="12">
          <a
            target="_blank"
            :href="
              $router.resolve(
                $modules.getModuleRoute('ticket', 'change-schedule-conflict', {
                  query: {
                    startDate,
                    endDate,
                  },
                  params: {
                    ticketType: moduleName,
                  },
                })
              ).href
            "
          >
            <MAlert :closable="false" class="my-2" banner type="warning">
              <div>
                {{ $t('change_schedule_conflict_message') }}
              </div>
            </MAlert>
          </a>
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="6" class="pr-0">
          <FlotoFormItem
            id="start-date-picker"
            :label="`${$t('start')} ${$t('date')}`"
          >
            <FlotoDatePicker
              :allow-clear="false"
              :disabled="disabled"
              :value="startDate"
              :max-date="endDate"
              :placeholder="$t('select')"
              @change="updateResource({ startDate: $event ? $event : 0 })"
            />
          </FlotoFormItem>
        </MCol>
        <MCol :size="6" class="pr-0">
          <FlotoFormItem
            id="end-date-picker"
            :label="`${$t('end')} ${$t('date')}`"
          >
            <FlotoDatePicker
              :allow-clear="false"
              :disabled="disabled"
              :value="endDate"
              :min-date="startDate"
              :placeholder="$t('select')"
              @change="updateResource({ endDate: $event ? $event : 0 })"
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MDivider />
      <h5 class="text-netural-light text-sm">
        {{ $tc('rolloutplan') }}
      </h5>
      <MRow>
        <MCol :size="6" class="pr-0">
          <FlotoFormItem
            id="start-date-picker-2"
            :label="`${$t('start')} ${$t('date')}`"
          >
            <FlotoDatePicker
              :disabled="disabled"
              :allow-clear="false"
              :value="rollOutDateStartTime"
              :min-date="startDate"
              :max-date="endDate"
              :placeholder="$t('select')"
              @change="
                updateResource({ rollOutDateStartTime: $event ? $event : 0 })
              "
            />
          </FlotoFormItem>
        </MCol>
        <MCol :size="6" class="pr-0">
          <FlotoFormItem
            id="end-date-picker-2"
            :label="`${$t('end')} ${$t('date')}`"
          >
            <FlotoDatePicker
              :disabled="disabled"
              :allow-clear="false"
              :value="rollOutDateEndTime"
              :min-date="startDate"
              :max-date="endDate"
              :placeholder="$t('select')"
              @change="
                updateResource({ rollOutDateEndTime: $event ? $event : 0 })
              "
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MDivider />
      <ChangeDownTimes
        v-if="rollOutDateStartTime && rollOutDateEndTime"
        :key="`${rollOutDateStartTime}${rollOutDateEndTime}`"
        :resource="resource"
        :disabled="disabled"
        :down-time-schedule="resource.downTimeSchedule"
        @change="updateResource({ downTimeSchedule: $event })"
      />
    </MCol>
    <MCol :size="6">
      <FlotoContentLoader :loading="loading">
        <h4 class="text-primary">{{ $t('planning') }}</h4>
        <template v-if="!loading">
          <WorkDetail
            v-model="work.impact"
            :disabled="disabledWork('impact')"
            prop-key="impact"
            :parent-id="resource.id"
            :module-name="moduleName"
          >
            <template v-slot:title>
              {{ $t('impact') }}
            </template>
          </WorkDetail>
          <WorkDetail
            v-model="work.rolloutPlan"
            :disabled="disabledWork('rolloutPlan')"
            prop-key="rolloutPlan"
            :parent-id="resource.id"
            :module-name="moduleName"
          >
            <template v-slot:title>
              {{ $t('rolloutplan') }}
            </template>
          </WorkDetail>
          <WorkDetail
            v-model="work.backoutPlan"
            :disabled="disabledWork('backoutPlan')"
            prop-key="backoutPlan"
            :parent-id="resource.id"
            :module-name="moduleName"
          >
            <template v-slot:title>
              {{ $t('backoutplan') }}
            </template>
          </WorkDetail>
          <WorkDetail
            v-if="moduleName === $constants.RELEASE"
            v-model="work.buildPlan"
            :disabled="disabledWork('buildPlan')"
            prop-key="buildPlan"
            :parent-id="resource.id"
            :module-name="moduleName"
          >
            <template v-slot:title>
              {{ $t('buildplan') }}
            </template>
          </WorkDetail>
          <WorkDetail
            v-if="moduleName === $constants.RELEASE"
            v-model="work.testPlan"
            :disabled="disabledWork('testPlan')"
            prop-key="testPlan"
            :parent-id="resource.id"
            :module-name="moduleName"
          >
            <template v-slot:title>
              {{ $t('testplan') }}
            </template>
          </WorkDetail>
        </template>
      </FlotoContentLoader>
    </MCol>
  </MRow>
</template>

<script>
import { getWorkApi } from '@components/conversation/api'
import { stageConstants } from '@utils/status'
import WorkDetail from './work-detail'
import ChangeDownTimes from './change-down-times/change-down-times'
export default {
  name: 'ChangeActivityTab',
  components: { WorkDetail, ChangeDownTimes },
  props: {
    disabled: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    updateApi: { type: Function, default: undefined },
    moduleName: { type: String, required: true },
    changeStage: { type: String, default: undefined },
  },
  data() {
    return {
      loading: true,
      work: {
        rootCause: {},
        symptomps: {},
        impact: {},
        workaround: {},
      },
      startDate: this.resource.startDate,
      endDate: this.resource.endDate,
      rollOutDateStartTime: this.resource.rollOutDateStartTime,
      rollOutDateEndTime: this.resource.rollOutDateEndTime,
    }
  },
  watch: {
    'resource.startDate': function(newValue) {
      if (newValue) {
        this.startDate = newValue
      }
    },
    'resource.endDate': function(newValue) {
      if (newValue) {
        this.endDate = newValue
      }
    },
    'resource.rollOutDateStartTime': function(newValue) {
      if (newValue) {
        this.rollOutDateStartTime = newValue
      }
    },
    'resource.rollOutDateEndTime': function(newValue) {
      if (newValue) {
        this.rollOutDateEndTime = newValue
      }
    },
  },
  created() {
    getWorkApi(this.moduleName, this.resource.id).then((data) => {
      this.work = data
      this.loading = false
    })
  },
  methods: {
    updateResource(change) {
      return this.updateApi({ id: this.resource.id, ...change })
    },
    disabledWork(work) {
      if (this.disabled) {
        if (this.moduleName === this.$constants.RELEASE) {
          if (
            this.changeStage === stageConstants.TESTING &&
            work === 'testPlan'
          ) {
            return false
          }
          if (
            this.changeStage === stageConstants.BUILD &&
            work === 'buildPlan'
          ) {
            return false
          }
        }
        return this.disabled
      }
      if (this.moduleName === this.$constants.RELEASE) {
        if (this.changeStage === stageConstants.DEPLOYMENT) {
          return true
        }
        if (
          [stageConstants.BUILD, stageConstants.TESTING].indexOf(
            this.changeStage
          ) === -1
        ) {
          return false
        }
        return true
      }
      return this.disabled
    },
  },
}
</script>
