var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-100"},[_c('FlotoCrudContainer',{ref:"categoryListRef",attrs:{"fetch-fn":_vm.getCategories,"paging":false,"update-fn":_vm.updateFn,"delete-fn":_vm.removeFn,"create-fn":_vm.createFn,"as-table":false},scopedSlots:_vm._u([{key:"add-controls",fn:function(ref){
var create = ref.create;
var refreshList = ref.refreshList;
return [_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex"},[_c('h5',{staticClass:"text-primary flex-1"},[_vm._v(" "+_vm._s(_vm.$tc('service'))+" "+_vm._s(_vm.$tc('category', 2))+" ")]),(!_vm.disabled)?_c('div',[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{attrs:{"id":"add-category-btn","shape":"circle","variant":"transparent","shadow":false},on:{"click":create}},[_c('MIcon',{staticClass:"text-primary",attrs:{"name":"plus-circle"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$tc('add'))+" "+_vm._s(_vm.$tc('service'))+" "+_vm._s(_vm.$tc('category', 2))+" ")])],1):_vm._e()]),_c('div',{staticClass:"mb-2"},[_c('MInput',{attrs:{"id":"search-box","type":"search","placeholder":_vm.$t('search')},on:{"search":refreshList},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)])]}},(!_vm.disabled)?{key:"form-header",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.id ? 'edit' : 'add'))+" "+_vm._s(_vm.$tc('service'))+" "+_vm._s(_vm.$tc('category'))+" ")]}}:null,{key:"form-items",fn:function(ref){
var folder = ref.item;
var resetForm = ref.resetForm;
return [_c('ServiceCategoryForm',{attrs:{"folder":folder,"reset-form":resetForm}})]}},{key:"form-actions",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
var item = ref.item;
var processing = ref.processing;
return [_c('MButton',{staticClass:"mx-1",attrs:{"id":"add-update-btn","loading":processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t(item.id ? 'update' : 'add'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}},{key:"list-items",fn:function(ref){
      var items = ref.items;
      var edit = ref.edit;
      var remove = ref.remove;
      var replaceAllItems = ref.replaceAllItems;
      var replaceItem = ref.replaceItem;
return [_c('div',{staticClass:"flex flex-col hierarchy-explorer flex-1 min-w-0"},[_c('ServiceCategoryItem',{attrs:{"active":(_vm.value || {}).id === 0,"folder":{
            id: 0,
            name: ((_vm.$tc('all')) + " " + (_vm.$tc('service')) + " " + (_vm.$tc('category', 2))),
          },"disabled":_vm.disabled},on:{"click":function($event){return _vm.setActiveFolder({ id: 0 })}}}),_c('SortableList',{attrs:{"value":items,"disabled":_vm.disabled,"tag":"div","filter":".sort-disabled"},on:{"update":function($event){return _vm.handleBulkUpdate($event, replaceAllItems)}}},[_vm._l((items),function(item){return _c('ServiceCategoryItem',{key:item.id,attrs:{"disabled":_vm.disabled,"active":(_vm.value || {}).id === item.id,"folder":item},on:{"click":function($event){return _vm.setActiveFolder(item)},"edit":function($event){return edit(item)},"remove":function($event){return _vm.handleRemoveFolder(item, remove, replaceItem)}}})}),_c('span')],2)],1)]}},{key:"pagination",fn:function(ref){
          var loadMoreItems = ref.loadMoreItems;
return [_c('MRow',[_c('MCol',{staticClass:"text-right"},[_c('a',{on:{"click":function($event){$event.preventDefault();return loadMoreItems.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('load_more'))+" ")])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }