<template>
  <div>
    <h5 class="text-netural-light text-sm">
      {{ $tc('down_time', 2) }}
    </h5>
    <template v-for="(schedule, index) in schedules">
      <DownTimeItem
        :key="schedule.startDate"
        v-bind="$attrs"
        :disabled="disabled"
        :schedule="schedule"
        :resource="resource"
        :count="index + 1"
        @remove="handleRemove"
        @submit="handleSubmit"
      />
    </template>
    <a
      v-if="!isAddingMode && !disabled"
      class="flex items-center mb-4"
      @click="handleAdd"
    >
      <MIcon name="plus-circle" size="lg" class="mr-1"></MIcon>
      {{ $t('add') }} {{ $tc('down_time') }}
    </a>
    <DownTimeForm
      v-if="isAddingMode"
      v-bind="$attrs"
      :disabled="disabled"
      :resource="resource"
      @submit="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import { generateId } from '@utils/id'
import DownTimeItem from './down-time-item'
import DownTimeForm from './down-time-form'
export default {
  name: 'ChangeDownTimes',
  components: { DownTimeItem, DownTimeForm },
  props: {
    disabled: { type: Boolean, default: false },
    downTimeSchedule: {
      type: Array,
      default() {
        return []
      },
    },
    resource: { type: Object, required: true },
  },
  data() {
    return {
      isAddingMode: false,
      schedules: this.downTimeSchedule.map((i) => ({
        ...i,
        guid: generateId(),
      })),
    }
  },
  watch: {
    downTimeSchedule(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.schedules = newValue.map((i) => ({
          ...i,
          guid: generateId(),
        }))
      }
    },
  },
  methods: {
    handleAdd() {
      this.isAddingMode = true
    },
    handleCancel() {
      this.isAddingMode = false
    },
    handleSubmit(data) {
      this.isAddingMode = false
      const index = this.schedules.map((i) => i.guid).indexOf(data.guid)
      if (index !== -1) {
        const schedules = [
          ...this.schedules.slice(0, index),
          data,
          ...this.schedules.slice(index + 1),
        ]
        this.$emit('change', schedules)
      } else {
        this.$emit('change', [...this.schedules, data])
      }
    },
    handleRemove(data) {
      const schedules = this.schedules.filter((s) => s.guid !== data.guid)
      this.$emit('change', schedules)
    },
  },
}
</script>
