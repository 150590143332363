import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: { moduleName, layout: 'SupportPortalLayout' },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "support-portal" */ './views/landing')
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'request',
        name: `${routeNamePrefix}.my-requests`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/my-requests'
            )
          ),
      },
      {
        path: 'request/create',
        name: `${routeNamePrefix}.create-request`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/create-request'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'self-service',
        name: `${routeNamePrefix}.self-service`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/self-service'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'question-answer-portal',
        name: `${routeNamePrefix}.question-answer-portal`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/question-answer-portal'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'reset-password',
        name: `${routeNamePrefix}.reset-password`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/reset-password'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'request/:id',
        name: `${routeNamePrefix}.view-request`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/request-view'
            )
          ),
      },
      {
        path: 'asset',
        name: `${routeNamePrefix}.my-assets`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "support-portal" */ './views/my-assets')
          ),
      },
      {
        path: 'knowledge',
        name: `${routeNamePrefix}.all-articles`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/all-articles'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'knowledge/:id',
        name: `${routeNamePrefix}.read`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/read-article'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'request-service',
        name: `${routeNamePrefix}.service-list`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/service-list'
            )
          ),
        meta: {
          breadcrumbKey: 'service',
        },
      },
      {
        path: 'request-service/:id',
        name: `${routeNamePrefix}.request-service`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/request-service'
            )
          ),
        meta: {
          breadcrumbKey: 'service',
        },
      },
      {
        path: 'my-approval',
        name: `${routeNamePrefix}.my-approvals`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/my-approval-list'
            )
          ),
        meta: {},
      },
      {
        path: 'my-approval/:id',
        name: `${routeNamePrefix}.view-approval`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/approval-detail'
            )
          ),
        meta: {},
      },
      {
        path: 'announcements',
        name: `${routeNamePrefix}.announcements`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/all-announcements'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'change',
        name: `${routeNamePrefix}.my-changes`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/my-changes'
            )
          ),
      },
      {
        path: 'change/:id',
        name: `${routeNamePrefix}.view-change`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-portal" */ './views/change-detail'
            )
          ),
      },
    ],
  },
]
