<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
export default {
  name: 'MyApprovalModule',
  page() {
    return {
      title: this.$t('my_approvals'),
    }
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
}
</script>
