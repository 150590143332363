import api from '@api'
import Constants from '@constants'

// import {
//   getRootTranslator,
// } from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import { transformTicketForDetail } from '@data/ticket'
import { transformAssetForDetail } from '@data/asset'
import { transformMyApproval, transformApprover } from '@data/approval'
import { transformKnowledge } from '@data/knowledge'
import { transformPurchaseForDetail } from '@data/purchase'

// const __t = getModuleTranslator(moduleConfig.translationKey)
// const __rootT = getRootTranslator()

export function getMyApprovalsApi(filterFactor, limit, offset) {
  return api
    .post(
      `${filterFactor.isPublic ? '/cportal' : ''}/myapproval/search/byqual`,
      {
        qualDetails: buildFlatQualificationStructure([
          buildRelationalQualificationStructure(
            'approver.approverStatus',
            'equal',
            filterFactor.status,
            'enum',
            'property'
          ),
          ...(filterFactor.filter
            ? [
                buildRelationalQualificationStructure(
                  'approval_stage_subject',
                  'contains',
                  filterFactor.filter,
                  undefined,
                  'variable'
                ),
              ]
            : []),
        ]),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformMyApproval),
        total: data.totalCount,
      }
    })
}

export function getApproverApi(id, isPublic = false) {
  return api
    .get(`${isPublic ? '/cportal' : ''}/approver/${id}`)
    .then(transformApprover)
}

export function getApprovalResourceDetailApi(moduleName, id, isPublic) {
  const apiSignature = isPublic
    ? `/cportal/${moduleName}/${id}`
    : `/${moduleName}/ref/approval/${id}`
  return api.get(apiSignature).then((data) => {
    if (
      [
        Constants.ASSET,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_NON_IT,
      ].indexOf(moduleName) >= 0
    ) {
      return transformAssetForDetail(data)
    } else if ([Constants.KNOWLEDGE].indexOf(moduleName) >= 0) {
      return transformKnowledge(data)
    } else if ([Constants.PURCHASE].indexOf(moduleName) >= 0) {
      return transformPurchaseForDetail(data)
    } else {
      return transformTicketForDetail(data)
    }
  })
}

export function getRequestChangeApprovalDetailApi(moduleName, id, isPublic) {
  const apiSignature = isPublic
    ? `/cportal/approval/${moduleName}/${id}`
    : `/${moduleName}/ref/approval/${id}`
  return api.get(apiSignature).then((data) => {
    return transformTicketForDetail(data)
  })
}
