import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformKnowledgeForServer,
  transformKnowledge,
  transformKnowledgeForList,
} from '@data/knowledge'
import { transformKeywordCriteriaForServer } from '@data/search'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@/src/data/qualification'
import { errorToast } from '@motadata/ui'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

function buildQualification(qualFactors) {
  const qual = []
  if (qualFactors.folderId) {
    qual.push(
      buildRelationalQualificationStructure(
        'knowledge.folderId',
        'equal',
        qualFactors.folderId,
        'integer'
      )
    )
  }
  return qual
}

export function searchKnowledgeApi(qualFactors, qualifications, limit, offset) {
  const mergedQuals = [
    ...qualifications,
    ...buildQualification(qualFactors || {}),
  ]
  return api
    .post(
      `${
        qualFactors && qualFactors.public ? '/public' : ''
      }/knowledge/search/byqual`,
      {
        ...(mergedQuals.length
          ? { qualDetails: buildFlatQualificationStructure(mergedQuals) }
          : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformKnowledgeForList),
        total: data.totalCount,
      }
    })
}

export function readKnowledgeApi(id, isPublicApi = false) {
  return api
    .get(`${isPublicApi ? '/public' : ''}/knowledge/read/${id}`)
    .then(transformKnowledge)
}

export function getKnowledgeApi(id) {
  return api.get(`/knowledge/${id}`).then(transformKnowledge)
}

export function updateKnowledgeApi(data) {
  return api
    .patch(`/knowledge/${data.id}`, transformKnowledgeForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('knowledge'),
      }),
    })
    .then(transformKnowledge)
}

export function removeKnowledgeApi(id) {
  return api
    .delete(`/knowledge/${id}`, {
      message: __rootT('deleted_successfully', {
        resource: __rootTc('knowledge'),
      }),
    })
    .then(transformKnowledge)
}

export function knowledgeAnalyticsApi(publicApi = false) {
  return api
    .get(`${publicApi ? '/public' : ''}/knowledge/analytic`)
    .then((data) => {
      return {
        mostRead: data.mostRead || [],
        mostHelpful: data.mostHelpful || [],
        mostSearchKeyword: data.mostSearchKeyword || [],
      }
    })
}

export function createKnowledgeApi(data) {
  return api.post('/knowledge', transformKnowledgeForServer(data), {
    message: __rootT('created_successfully', {
      resource: __rootTc('knowledge'),
    }),
  })
}

export function registerKnowledgeFeedbackApi(kbId, helpful, isPublic = false) {
  return api
    .post(
      `${isPublic ? '/public' : ''}/knowledge/${kbId}/feedback`,
      { helpful },
      { notify: false }
    )
    .catch((e) => {
      errorToast(((e.response || {}).data || {}).userMessage)
    })
}

export function removeKnowledgeFeedbackApi(kbId, isPublic = false) {
  return api.delete(`${isPublic ? '/public' : ''}/knowledge/${kbId}/feedback`, {
    notify: false,
  })
}

export function restoreKnowledgeApi(kbId, folderId) {
  return api.post(
    `/knowledge/${kbId}/restore`,
    {
      folderId,
    },
    { notify: true }
  )
}

export function relatedKnowledgeForInsertKBApi(
  publicApi = false,
  { keyword, kbId, ...rest },
  offset,
  limit
) {
  return api
    .post(
      `${publicApi ? '/public' : ''}/knowledge/showRelated/search/byqual`,
      {
        qualDetails: buildFlatQualificationStructure([
          ...(kbId
            ? [
                buildRelationalQualificationStructure(
                  'id',
                  'in',
                  [kbId],
                  'long'
                ),
              ]
            : [transformKeywordCriteriaForServer([keyword])]),
        ]),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 10 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformKnowledgeForList),
        total: (data.objectList || []).length,
      }
    })
}

export function relatedKnowledgeForCreateTicketApi(
  publicApi = false,
  moduleName,
  data,
  offset,
  limit
) {
  return api
    .post(
      `${publicApi ? '/public' : ''}/knowledge/${moduleName}/smartsuggest`,
      {
        subject: data.subjectText,
        description: data.descriptionText,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 10 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformKnowledgeForList),
        total: (data.objectList || []).length,
      }
    })
}

export function relatedKnowledgeApi(publicApi = false, keyword, offset, limit) {
  return api
    .post(
      `${publicApi ? '/public' : ''}/knowledge/showRelated/search/byqual`,
      {
        qualDetails: buildFlatQualificationStructure([
          transformKeywordCriteriaForServer([keyword]),
        ]),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 10 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformKnowledgeForList),
        total: (data.objectList || []).length,
      }
    })
}
