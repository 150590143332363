var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MRow',{attrs:{"gutter":28}},[_c('MCol',[_c('MRow',{attrs:{"gutter":0}},[_c('MCol',[_c('h6',{staticClass:"text-primary-alt font-semibold"},[_vm._v(_vm._s(_vm.$t('other_info')))])])],1),_vm._t("before"),(
        (!_vm.allowedFields || _vm.allowedFields.indexOf('categoryId') >= 0) &&
        _vm.hiddenFields.indexOf('categoryId') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('categoryId') }},[_vm._v(" "+_vm._s(_vm.$tc('category'))+" ")])]),_c('div',{attrs:{"id":"category-picker"}},[_c('FlotoCategoryPicker',{staticClass:"w-full",class:{
              'read-only material-input': _vm.disabled,
              'material-input': !_vm.disabled,
            },attrs:{"value":_vm.value.categoryId,"suggested-value":_vm.suggestedCategoryId,"full-path":true,"disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"module-name":_vm.moduleName,"allow-clear":!_vm.isRequiredFn('categoryId')},on:{"change":function($event){return _vm.handleChange({ categoryId: $event })}}})],1)])],1):_vm._e(),(
        (!_vm.allowedFields || _vm.allowedFields.indexOf('departmentId') >= 0) &&
        _vm.hiddenFields.indexOf('departmentId') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('departmentId') }},[_vm._v(" "+_vm._s(_vm.$tc('department'))+" ")])]),_c('div',{attrs:{"id":"department-picker"}},[_c('FlotoDepartmentPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.departmentId,"input-classes":"material-input","disabled":_vm.disabled,"full-path":"","placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('departmentId')},on:{"change":function($event){return _vm.handleChange({ departmentId: $event })}}})],1)])],1):_vm._e(),(
        (!_vm.allowedFields || _vm.allowedFields.indexOf('companyId') >= 0) &&
        _vm.enableMsp
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('companyId') }},[_vm._v(" "+_vm._s(_vm.$tc('company'))+" ")])]),_c('div',{attrs:{"id":"company-picker"}},[_c('CompanyPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.companyId,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('companyId')},on:{"change":function($event){return _vm.handleChange({ companyId: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.REQUEST &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('sourceId') >= 0) &&
        _vm.hiddenFields.indexOf('sourceId') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('source'))+" ")])]),_c('div',{attrs:{"id":"source-picker"}},[_c('FlotoSourcePicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.sourceId,"input-classes":"material-input","disabled":_vm.disabled,"allow-clear":false,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ sourceId: $event })}}})],1)])],1):_vm._e(),(
        (!_vm.allowedFields || _vm.allowedFields.indexOf('locationId') >= 0) &&
        _vm.hiddenFields.indexOf('locationId') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('locationId') }},[_vm._v(" "+_vm._s(_vm.$tc('location'))+" ")])]),_c('div',{attrs:{"id":"location-picker"}},[_c('FlotoLocationPicker',{staticClass:"material-input w-full",class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.locationId,"disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"full-path":"","allow-clear":!_vm.isRequiredFn('locationId')},on:{"change":function($event){return _vm.handleChange({ locationId: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.PROBLEM &&
        _vm.hiddenFields.indexOf('businessServiceId') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{
              'field-lable-required': _vm.isRequiredFn('businessServiceId'),
            }},[_vm._v(" "+_vm._s(_vm.$t('business'))+" "+_vm._s(_vm.$tc('service'))+" ")])]),_c('div',{attrs:{"id":"business-service-picker"}},[_c('FlotoBusinessServicePicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.businessServiceId,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('businessServiceId')},on:{"change":function($event){return _vm.handleChange({ businessServiceId: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.PROBLEM &&
        _vm.hiddenFields.indexOf('natureOfProblem') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{
              'field-lable-required': _vm.isRequiredFn('natureOfProblem'),
            }},[_vm._v(" "+_vm._s(_vm.$t('nature_of_problem'))+" ")])]),_c('div',{attrs:{"id":"nature-of-problem-dropdown"}},[_c('FlotoDropdownPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.natureOfProblem,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('natureOfProblem'),"options":[
              { text: _vm.$t('proactive'), key: 'proactive' },
              { text: _vm.$t('reactive'), key: 'reactive' } ]},on:{"change":function($event){return _vm.handleChange({ natureOfProblem: $event || null })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.PROBLEM &&
        _vm.hiddenFields.indexOf('knownError') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('knownError') }},[_vm._v(" "+_vm._s(_vm.$t('known_error'))+" ")])]),_c('div',{attrs:{"id":"known-error-dropdown"}},[_c('FlotoDropdownPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.knownError,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('knownError'),"options":[
              { text: _vm.$t('yes'), key: 'true' },
              { text: _vm.$t('no'), key: 'false' } ]},on:{"change":function($event){return _vm.handleChange({ knownError: $event === 'true' })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.CHANGE &&
        _vm.hiddenFields.indexOf('reasonType') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('reasonType') }},[_vm._v(" "+_vm._s(_vm.$tc('change'))+" "+_vm._s(_vm.$t('reason'))+" ")])]),_c('div',{attrs:{"id":"reason-type-picker"}},[_c('FlotoReasonTypePicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.reasonType,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('reasonType')},on:{"change":function($event){return _vm.handleChange({ reasonType: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.CHANGE &&
        _vm.hiddenFields.indexOf('changeTypeId') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('changeTypeId') }},[_vm._v(" "+_vm._s(_vm.$tc('change_type'))+" ")])]),_c('div',{attrs:{"id":"change-type-dropdown"}},[_c('FlotoChangeTypePicker',{attrs:{"value":_vm.value.changeTypeId,"input-classes":"material-input","disabled":_vm.disabled || _vm.changeStage === _vm.stageConstants.APPROVAL,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('changeTypeId')},on:{"change":function($event){return _vm.handleChange({ changeTypeId: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.RELEASE &&
        _vm.hiddenFields.indexOf('reasonType') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('reasonType') }},[_vm._v(" "+_vm._s(_vm.$tc('release'))+" "+_vm._s(_vm.$t('reason'))+" ")])]),_c('div',{attrs:{"id":"release-reason-type-picker"}},[_c('ReleaseReasonTypePicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.reasonType,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('reasonType')},on:{"change":function($event){return _vm.handleChange({ reasonType: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.RELEASE &&
        _vm.hiddenFields.indexOf('releaseTypeId') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{ 'field-lable-required': _vm.isRequiredFn('releaseTypeId') }},[_vm._v(" "+_vm._s(_vm.$tc('release_type'))+" ")])]),_c('div',{attrs:{"id":"release-type-dropdown"}},[_c('ReleaseTypePicker',{attrs:{"value":_vm.value.releaseTypeId,"input-classes":"material-input","disabled":_vm.disabled || _vm.changeStage === _vm.stageConstants.APPROVAL,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('releaseTypeId')},on:{"change":function($event){return _vm.handleChange({ releaseTypeId: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.RELEASE &&
        _vm.hiddenFields.indexOf('affectedServices') === -1
      )?_c('MRow',[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{
              'field-lable-required': _vm.isRequiredFn('affectedServices'),
            }},[_vm._v(" "+_vm._s(_vm.$t('business'))+" "+_vm._s(_vm.$tc('service'))+" ")])]),_c('div',{attrs:{"id":"business-service-picker"}},[_c('FlotoBusinessServicePicker',{class:{ 'read-only': _vm.disabled },style:({ lineHeight: _vm.disabled ? 2.3 : '' }),attrs:{"value":_vm.value.affectedServices,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","multiple":"","show-divider":"","allow-clear":!_vm.isRequiredFn('affectedServices')},on:{"change":function($event){return _vm.handleChange({ affectedServices: $event })}}})],1),_c('MDivider',{staticClass:"mb-0 mt-0"})],1)],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.CHANGE &&
        _vm.hiddenFields.indexOf('targetEnvironment') === -1
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable",class:{
              'field-lable-required': _vm.isRequiredFn('targetEnvironment'),
            }},[_vm._v(" "+_vm._s(_vm.$t('target_environment'))+" ")])]),_c('div',{attrs:{"id":"target-environment-picker"}},[_c('FlotoTargetEnvironmentPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.targetEnvironment,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":"","allow-clear":!_vm.isRequiredFn('targetEnvironment')},on:{"change":function($event){return _vm.handleChange({ targetEnvironment: $event })}}})],1)])],1):_vm._e(),(_vm.moduleName === _vm.$constants.CHANGE)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('implementer'))+" ")])]),_c('div',{attrs:{"id":"change-implementer-dropdown"}},[_c('FlotoTechnicianPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.changeImplementor,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ changeImplementor: $event })}}})],1)])],1):_vm._e(),(_vm.moduleName === _vm.$constants.RELEASE)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('release'))+" "+_vm._s(_vm.$t('engineer'))+" ")])]),_c('div',{attrs:{"id":"release-engineer-dropdown"}},[_c('FlotoTechnicianPicker',{class:{
              'read-only': _vm.disabled || _vm.changeStage === _vm.stageConstants.TESTING,
            },attrs:{"value":_vm.value.releaseEngineer,"input-classes":"material-input","disabled":_vm.disabled || _vm.changeStage === _vm.stageConstants.TESTING,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ releaseEngineer: $event })}}})],1)])],1):_vm._e(),(_vm.moduleName === _vm.$constants.CHANGE)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('manager'))+" ")])]),_c('div',{attrs:{"if":"manager-dropdown"}},[_c('FlotoTechnicianPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.changeManager,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ changeManager: $event })}}})],1)])],1):_vm._e(),(_vm.moduleName === _vm.$constants.RELEASE)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('release'))+" "+_vm._s(_vm.$t('manager'))+" ")])]),_c('div',{attrs:{"id":"release-manager-dropdown"}},[_c('FlotoTechnicianPicker',{class:{
              'read-only':
                _vm.disabled ||
                _vm.changeStage === _vm.stageConstants.BUILD ||
                _vm.changeStage === _vm.stageConstants.TESTING ||
                _vm.changeStage === _vm.stageConstants.DEPLOYMENT,
            },attrs:{"value":_vm.value.releaseManager,"input-classes":"material-input","disabled":_vm.disabled ||
              _vm.changeStage === _vm.stageConstants.BUILD ||
              _vm.changeStage === _vm.stageConstants.TESTING ||
              _vm.changeStage === _vm.stageConstants.DEPLOYMENT,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ releaseManager: $event })}}})],1)])],1):_vm._e(),(_vm.moduleName === _vm.$constants.RELEASE)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('qa'))+" "+_vm._s(_vm.$t('manager'))+" ")])]),_c('div',{attrs:{"id":"qa-manager-dropdown"}},[_c('FlotoTechnicianPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.qaManager,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ qaManager: $event })}}})],1)])],1):_vm._e(),(_vm.moduleName === _vm.$constants.CHANGE)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('reviewer'))+" ")])]),_c('div',{attrs:{"id":"change-reviewer-dropdown"}},[_c('FlotoTechnicianPicker',{class:{
              'read-only':
                _vm.changeStage === _vm.stageConstants.REVIEW ? false : _vm.disabled,
            },attrs:{"value":_vm.value.changeReviewer,"input-classes":"material-input","disabled":_vm.changeStage === _vm.stageConstants.REVIEW ? false : _vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ changeReviewer: $event })}}})],1)])],1):_vm._e(),(_vm.moduleName === _vm.$constants.RELEASE)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('release'))+" "+_vm._s(_vm.$t('reviewer'))+" ")])]),_c('div',{attrs:{"id":"release-reviewer-dropdown"}},[_c('FlotoTechnicianPicker',{class:{
              'read-only':
                _vm.changeStage === _vm.stageConstants.REVIEW ? false : _vm.disabled,
            },attrs:{"value":_vm.value.releaseReviewer,"input-classes":"material-input","disabled":_vm.changeStage === _vm.stageConstants.REVIEW ? false : _vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ releaseReviewer: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.REQUEST &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('supportLevel') >= 0)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('support_level'))+" ")])]),_c('div',{attrs:{"id":"support-level-picker"}},[_c('FlotoSupportLevelPicker',{class:{ 'read-only': _vm.disabled },attrs:{"value":_vm.value.supportLevel,"input-classes":"material-input","disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"as-input":""},on:{"change":function($event){return _vm.handleChange({ supportLevel: $event })}}})],1)])],1):_vm._e(),(!_vm.allowedFields || _vm.allowedFields.indexOf('violatedSlaId') >= 0)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('current_sla'))+" ")])]),_c('div',{attrs:{"id":"sla"}},[_c('SlaName',{attrs:{"sla-id":_vm.value.violatedSlaId,"module-name":_vm.moduleName}})],1)])],1):_vm._e(),(
        [
          [
            _vm.$constants.REQUEST,
            _vm.$constants.PROBLEM,
            _vm.$constants.CHANGE,
            _vm.$constants.RELEASE ].indexOf(_vm.moduleName) >= 0 ] &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('escalationLevel') >= 0)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',{staticClass:"material-input"},[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('escalation_level'))+" ")])]),_c('div',{staticClass:"ant-input",attrs:{"id":"escalation-level"}},[_vm._v(" "+_vm._s(_vm.value.escalationLevel)+" ")])])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.REQUEST &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('responseDue') >= 0)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',{staticClass:"material-input"},[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('first_response_due_by'))+" ")])]),_c('div',{staticClass:"ant-input",attrs:{"id":"first-response-time"}},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.value.responseDue))+" ")])])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.REQUEST &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('firstResponseTime') >= 0)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',{staticClass:"material-input"},[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('first_response_date'))+" ")])]),_c('div',{staticClass:"ant-input",attrs:{"id":"first-response-date"}},[_vm._v(" "+_vm._s(_vm._f("datetime")(!_vm.value.firstResponseTime ? '---' : _vm.value.firstResponseTime))+" ")])])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.REQUEST &&
        _vm.value.incomingMailbox &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('incomingMailbox') >= 0)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',{staticClass:"material-input"},[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('incoming_mail_box'))+" ")])]),_c('div',{staticClass:"ant-input",attrs:{"id":"incoming-mailbox-picker"}},[_c('FlotoTagsPicker',{attrs:{"value":[_vm.value.incomingMailbox],"type":"email","disabled":""}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.REQUEST &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('ccEmailSet') >= 0)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('cc_email', 2))+" ")])]),_c('div',{staticClass:"bordered-bottom",attrs:{"id":"cc-emailset-picker"}},[_c('FlotoTagsPicker',{staticClass:"mb-1",attrs:{"value":_vm.value.ccEmailSet,"title":_vm.$tc('cc_email'),"type":"email","confirm-before-remove":true,"disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"show-empty":""},on:{"change":function($event){return _vm.handleChange({ ccEmailSet: $event })}}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.REQUEST &&
        _vm.value.callFrom &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('callFrom') >= 0)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',{staticClass:"material-input"},[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('call'))+" "+_vm._s(_vm.$tc('from'))+" ")])]),_c('div',{staticClass:"ant-input",attrs:{"id":"call-from"}},[_vm._v(" "+_vm._s(_vm.value.callFrom || '---')+" ")])])],1):_vm._e(),(!_vm.allowedFields || _vm.allowedFields.indexOf('templateId') >= 0)?_c('MRow',{staticClass:"my-3"},[_c('MCol',{staticClass:"material-input"},[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('template'))+" ")])]),_c('div',{staticClass:"ant-input",attrs:{"id":"template-picker"}},[_c('FlotoTemplatePicker',{staticClass:"material-input",attrs:{"value":_vm.value.templateId,"module-name":_vm.moduleName,"disabled":"","placeholder":"---","text-only":""}})],1)])],1):_vm._e(),(
        _vm.moduleName === _vm.$constants.REQUEST &&
        _vm.value.requestType &&
        (!_vm.allowedFields || _vm.allowedFields.indexOf('requestType') >= 0)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',{staticClass:"material-input"},[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$tc('request'))+" "+_vm._s(_vm.$t('type'))+" ")])]),_c('div',{staticClass:"ant-input",attrs:{"id":"request-type"}},[_vm._v(" "+_vm._s(_vm.$tc(_vm.value.requestType))+" ")])])],1):_vm._e(),(
        !_vm.isPortalLogin || (_vm.isPortalLogin && _vm.moduleName === _vm.$constants.CHANGE)
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('last_updated_date'))+" ")])]),_c('div',{attrs:{"id":"updated-at"}},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.value.updatedAt))+" ")]),_c('div',{attrs:{"id":"updated-at"}},[_vm._v(" ("+_vm._s(_vm._f("timeago")(_vm.value.updatedAt))+") ")])])],1):_vm._e(),(_vm.value.resolvedAt > 0 && !_vm.isPortalLogin)?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('last_resolved_time'))+" ")])]),_c('div',{attrs:{"id":"resolved-at"}},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.value.resolvedAt,'ddd, MMM DD, YYYY hh:mm A'))+" ")]),_c('div',{attrs:{"id":"resolved-at"}},[_vm._v(" ("+_vm._s(_vm._f("timeago")(_vm.value.resolvedAt))+") ")])])],1):_vm._e(),(
        _vm.value.closedAt > 0 &&
        (!_vm.isPortalLogin ||
          (_vm.isPortalLogin && _vm.moduleName === _vm.$constants.CHANGE))
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('last_closed_time'))+" ")])]),_c('div',{attrs:{"id":"closed-at"}},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.value.closedAt,'ddd, MMM DD, YYYY hh:mm A'))+" ")]),_c('div',{attrs:{"id":"closed-at"}},[_vm._v(" ("+_vm._s(_vm._f("timeago")(_vm.value.closedAt))+") ")])])],1):_vm._e(),(
        [_vm.$constants.REQUEST, _vm.$constants.PROBLEM].indexOf(_vm.moduleName) >= 0 &&
        (_vm.isTicketClosed || _vm.isTicketResolve) &&
        !_vm.isPortalLogin
      )?_c('MRow',{staticClass:"my-3"},[_c('MCol',[_c('div',[_c('small',{staticClass:"sidebar-field-lable"},[_vm._v(" "+_vm._s(_vm.$t('time', { type: _vm.$t('resolution') }))+" ")])]),_c('div',{attrs:{"id":"resolution-time"}},[(_vm.value.resolutionTime === 0)?_c('span',[_vm._v("0 "+_vm._s(_vm.$tc('seconds')))]):_c('span',[_vm._v(_vm._s(_vm._f("duration")(_vm.value.resolutionTime)))])])])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }